<app-loader *ngIf="bLoadingData"></app-loader>

<div class="container pt-4 pb-4 bg-white">

  <div class="row">
    <div class="col-12">
      <h3>Archivio spedizioni</h3>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-12">
      <div class="d-flex flex-wrap align-items-end border bg-light p-3">
        <div class="flex-fill me-2 mt-2">
          <label>Dal</label>
          <input type="date" class="form-control" [(ngModel)]="oFilter.from">
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>Al</label>
          <input type="date" class="form-control" [(ngModel)]="oFilter.to">
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>Corriere</label>
          <select class="form-control" [(ngModel)]="oFilter.courier">
            <option value="--">-- Seleziona corriere --</option>
            <option *ngFor="let courier of vrshippingService.settingsData.couriers" [value]="courier.code">{{courier.description}}</option>
          </select>
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>Mittente</label>
          <input type="text" class="form-control" placeholder="Veronasped" [(ngModel)]="oFilter.sender">
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>ID</label>
          <input type="text" class="form-control" placeholder="1234567" [(ngModel)]="oFilter.ship_id">
        </div>
        <div class="flex-fill me-2 mt-2 mb-1">
          <button class="btn btn-sm btn-primary" (click)="filterShipList()">FILTRA</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2 mb-4">
    <div class="col-12">
      <div *ngFor="let ship of filterList(aoShipList)" class="border bg-primary p-2 me-2 mt-3" style="--bs-bg-opacity: .1;">
        <div class="row mt-2">
          <div class="col-12 mb-3">
            <div class="d-flex flex-wrap">
              <div class="flex-fill">
                <label>ID: {{ship.ship_id}}</label>
                <span class="small text-muted ms-2">del {{ship.ins_date | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
                <div *ngIf="ship.courier_code == 'BRT'">
                  <span class="badge bg-success" *ngIf="ship.dispatch_number == 'transfer OK'">Ritiro richiesto alla filiale</span>
                  <span class="badge bg-primary" *ngIf="ship.dispatch_number == ''">Ritiro in attesa di invio</span>
                  <span class="badge bg-danger" *ngIf="ship.dispatch_number == 'transfer KO'">Errore nell'invio</span>
                </div>
              </div>
              <div class="flex-fill text-end">
                <img src="assets/img/{{ship.courier_code.toLowerCase()}}/logo.png" class="logo-courier" alt="{{ship.courier_code}}" />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-2"><label>MITTENTE</label></div>
            {{ship.mit_agency}}
            <div class="small text-muted mb-2">{{ship.mit_address}} - {{ship.mit_zip}} {{ship.mit_state}}</div>
            <div><i class="fa fa-calendar-check"></i> {{ship.date | date: 'dd/MM/yyyy'}}</div>
            <div><i class="fa fa-user-clock"></i> entro le {{ship.hour.slice(0, 5)}}</div>
          </div>
          <div class="col-3">
            <div class="mb-2"><label>DESTINATARIO</label></div>
            {{ship.dest_agency}}
            <div class="small text-muted mb-2">{{ship.dest_address}} - {{ship.dest_zip}} {{ship.dest_state}}</div>
            <div *ngIf="ship.courier_code == 'DHL'">
              <div><i class="fa fa-calendar-check"></i> {{ship.delivery | date: 'dd/MM/yyyy'}}</div>
              <div><i class="fa fa-user-clock"></i> entro le {{ship.delivery | date: 'HH:mm'}}</div>
            </div>
            <div *ngIf="ship.courier_code == 'BRT'">
              <div><i class="fa fa-calendar-check"></i> da 1 a 3 giorni</div>
            </div>
            <div *ngIf="ship.courier_code == 'FEDEX'">
              <div><i class="fa fa-calendar-check"></i> come da indicazioni ricevute</div>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-2"><label>COLLI</label></div>
            <div class="mt-2 small" *ngFor="let pack of ship.packages">
              <i class="fa fa-box"></i> {{pack.number}} H:{{pack.height}} W:{{pack.width}} L:{{pack.length}} - {{pack.weight}}Kg
              <div class="small text-muted">Dichiarato: {{pack.weight_declared}}Kg - Volumetrico: {{pack.weight_vol}}Kg</div>
            </div>
            <div class="small mt-2" *ngIf="ship.duty_declared > 0">
              <label>Valore economico</label>: {{ship.duty_declared | number: '1.2-2'}} Euro
            </div>
            <div class="small mt-2" *ngIf="ship.insurrence_declared > 0">
              <label>Valore da assicurare</label>: {{ship.insurrence_declared | number: '1.2-2'}} Euro
            </div>

            <div class="small mt-2" *ngIf="ship.courier_code == 'BRT'">
              <div *ngIf="ship.pallet_EPAL > 0"><label>Pallet EPAL: </label> {{ship.pallet_EPAL}}</div>
              <div *ngIf="ship.pallet_EUR > 0"><label>Pallet EUR: </label> {{ship.pallet_EUR}}</div>
              <div *ngIf="ship.pallet_tail_lift =='S'">
                <label>Sponda idraulica: </label>
                <i class="fa fa-check-circle text-success ms-1" ></i>
              </div>
            </div>

            <div class="mt-2">
              <div class="d-flex mt-2 small text-muted">
                <div class="me-2 mt-2" [class]="{'text-success': ship.insurrence_cost > 0}"><i class="fa fa-car-crash"></i> Assicurazione</div>
                <div class="text-success me-2 mt-2" [class]="{'text-success': ship.duty_type == 'DDP'}"><i class="fa fa-globe"></i> Disbrigo pratiche</div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="mb-2"><label>COSTI</label></div>
            <table class="table table-responsive table-hover small">
              <!--<thead>
                <tr>
                  <th>Voce</th>
                  <th>Valore (euro)</th>
                </tr>
              </thead>-->
              <tbody>
                <tr>
                  <td>Spedizione</td>
                  <td class="text-end">{{ship.price | number: '1.2-2'}}</td>
                </tr>
                <tr>
                  <td>Fuel</td>
                  <td class="text-end">{{ship.fuel_price | number: '1.2-2'}}</td>
                </tr>
                <tr *ngIf="ship.duty_cost > 0">
                  <td>Disbrigo pratiche</td>
                  <td class="text-end">{{ship.duty_cost | number: '1.2-2'}}</td>
                </tr>
                <tr *ngIf="ship.insurrence_cost > 0">
                  <td>Assicurazione</td>
                  <td class="text-end">{{ship.insurrence_cost | number: '1.2-2'}}</td>
                </tr>
                <tr *ngIf="ship.remote_zone > 0">
                  <td>Zona remota</td>
                  <td class="text-end">{{ship.remote_zone | number: '1.2-2'}}</td>
                </tr>
              </tbody>
            </table>
            <div class="badge bg-success">
              Totale {{ship.price + ship.fuel_price + ship.duty_cost + ship.insurrence_cost + ship.remote_zone | number:'1.2-2'}} Euro
            </div>
          </div>
          <div class="col-12 mt-3 text-center" *ngIf="ship.courier_code !== 'FEDEX'">
            <button class="btn btn-sm btn-primary me-2" (click)="trackingData(ship)">Tracking</button>
            <button class="btn btn-sm btn-primary" (click)="onClickDownloadPdf(ship)">Download etichette</button>
            <button class="btn btn-danger btn-sm ms-4" (click)="deleteShipRequest(ship)" *ngIf="vrshippingService.userData.type == 'A'"><i class="fa fa-trash"></i> ELIMINA SPEDIZIONE</button>
          </div>
          <div class="col-12 mt-3 text-center" *ngIf="ship.courier_code == 'FEDEX'">
            <button class="btn btn-sm btn-primary me-2" *ngFor="let pack of ship.packages" (click)="trackingDataFedex(pack.tracking)">Tracking {{pack.tracking}}</button>
            <button class="btn btn-sm btn-primary" (click)="onClickDownloadPdf(ship)">Download etichette</button>
            <button class="btn btn-danger btn-sm ms-4" (click)="deleteShipRequest(ship)" *ngIf="vrshippingService.userData.type == 'A'"><i class="fa fa-trash"></i> ELIMINA SPEDIZIONE</button>
          </div>
          <div class="col-12 mt-3 text-center" *ngIf="vrshippingService.userData.type == 'A' && ship.courier_code == 'BRT'">
            <button class="btn btn-warning btn-sm ms-4" (click)="brtRegenerateFile(ship)">RIGENERA PICKUP</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
