import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {addressProfile, shipData} from "../../interfaces/vrs_interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-ship-sender',
  templateUrl: './ship-sender.component.html',
  styleUrls: ['./ship-sender.component.scss']
})
export class ShipSenderComponent implements OnInit {

  @Input() oShipData: shipData = this.vrshippingService.getNewshipData();
  @Output() saveAnswer = new EventEmitter<shipData>();

  public oProfile: addressProfile = this.vrshippingService.getNewAddressProfile();

  public bLoadingData: boolean = false;
  public aSenders: Array<addressProfile> = [];
  public aAvailableSenders: Array<addressProfile> = [];
  public oAction: any = {
    status: false,
    addressProfile: ""
  }

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal, public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.getSenderList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getSenderList () {

    this.bLoadingData = true;

    this.vrshippingService.getAddressProfileListDB(this.vrshippingService.userData.token, 'sender').subscribe(get_response => {

        if(get_response.profiles && get_response.profiles.error) {
          //Error
          this.openDialog('danger', "Errore nel caricamento dei mittenti: " + get_response.profiles.error);
        } else {

          this.aSenders = get_response.profiles;
        }
        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', "Errore imprevisto del server: " + error.message);
        this.bLoadingData = false;
      });
  }

  checkSenderConsistency() {

    if(this.oShipData.mit_reference == '') return false;
    if(this.oShipData.mit_agency == '') return false;
    if(this.oShipData.mit_phone == '') return false;
    if(this.oShipData.mit_state == '--') return false;
    if(this.oShipData.mit_city == '') return false;
    if(this.oShipData.mit_address == '') return false;
    if(this.oShipData.mit_zip == '') return false;
    if(this.oShipData.mit_state == 'IT' || this.oShipData.mit_state == 'US'){
      if(this.oShipData.mit_us_prov == '') return false;
    }

    return true;

  }

  filterList(){
    this.aAvailableSenders = [];
    if(this.oAction.addressProfile && this.oAction.addressProfile.length >= 3) {
      this.aAvailableSenders = this.aSenders.filter(x => x.azienda.toLowerCase().indexOf(this.oAction.addressProfile.toLowerCase()) !== -1 || x.rif_cognome.toLowerCase().indexOf(this.oAction.addressProfile.toLowerCase()) !== -1);
    }
  }

  selectSender(sender: addressProfile){

    this.oProfile.id = 0;
    this.oShipData.cod_mit = sender.id;
    this.oShipData.mit_agency = sender.azienda;
    this.oShipData.mit_reference = sender.rif_cognome;
    //this.oShipData.mit_email = sender.rif_email;
    this.oShipData.mit_email = "spedizioni@veronasped.it";
    this.oShipData.mit_phone = sender.tel;
    this.oShipData.mit_state = sender.nazione;
    if(this.oShipData.mit_state == 'IT') this.oShipData.mit_us_prov = sender.prov;
    if(this.oShipData.mit_state == 'US') this.oShipData.mit_us_prov = sender.usstate;
    this.oShipData.mit_city = sender.citta;
    this.oShipData.mit_zip = sender.cap;
    this.oShipData.mit_address = sender.indirizzo;
    this.oShipData.mit_address_2 = sender.indirizzo2;
    this.oShipData.client_reference = sender.reference;

    this.oAction.addressProfile = "";
    this.aAvailableSenders = [];

  }

  insertProfile() {

    this.bLoadingData = true;

    this.oProfile.id = 0;
    this.oProfile.azienda = this.oShipData.mit_agency;
    this.oProfile.rif_cognome = this.oShipData.mit_reference;
    this.oProfile.rif_nome = "";
    this.oProfile.rif_email = this.oShipData.mit_email;
    this.oProfile.tel = this.oShipData.mit_phone;
    this.oProfile.nazione = this.oShipData.mit_state;
    this.oProfile.prov = this.oShipData.mit_us_prov;
    this.oProfile.usstate = this.oShipData.mit_us_prov;
    this.oProfile.citta = this.oShipData.mit_city;
    this.oProfile.cap = this.oShipData.mit_zip;
    this.oProfile.indirizzo = this.oShipData.mit_address;
    this.oProfile.indirizzo2 = this.oShipData.mit_address_2;

    this.vrshippingService.postAddressProfileInsertDB(this.oProfile, 'sender', this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.profiles && insert_response.profiles.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + JSON.stringify(insert_response.profiles.error));
        } else {
          this.openDialog('success', 'Profilo inserito con successo!');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  saveProfile(){
    if(this.oAction.status) this.insertProfile();
    this.saveAnswer.emit(this.oShipData);
  }

}
