<div class="container mt-4 mb-4">
  <div class="row">
    <div class="col-md-12">
      <div class="jumbotron text-center">
        <h1>Oops!</h1>
        <h2>404 Not Found</h2>
        <div class="error-details">
          Siamo desolati ma la pagina che stai cercando non è stata trovata!
        </div>
        <button class="btn btn-primary btn-lg mt-4" routerLink="/home">
          <span class="fa fa-home"></span> Torna alla home
        </button>
      </div>
    </div>
  </div>
</div>


