<div class="container-fluid footer-bar d-flex align-items-center fixed-bottom">
  <div class="container d-flex align-items-center justify-content-between">
    <div class="d-none d-lg-flex">
      VRShipping |
      <span class="ms-1">ASSISTENZA:</span>
      <span class="ms-1">Chiama il <a href="tel:00390458620589">+39 045 8620589</a> oppure scrivi a <a href="mailto:assistenza@veronasped.it">assistenza@veronasped.it</a></span>
    </div>
    <div>
      Version: {{appVersion}}-{{appRevision}} | {{appLine}}
    </div>
  </div>
</div>
