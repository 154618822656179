import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  get(url: string, token: string, params?: HttpParams): Observable<any> {

    const completedUrl = environment.apiUrl + url;

    let headers = new HttpHeaders({
      'Authorization': token
    });

    let options = {
      headers: headers
    }

    return this.http.get(completedUrl, options);

  }

  post(url: string, token: string, obj: any): Observable<any> {

    const completedUrl = environment.apiUrl + url;
    let headers = new HttpHeaders({
      'Authorization': token
    });

    let options = {
      headers: headers
    }

    return this.http.post(completedUrl, obj, options);

  }

}
