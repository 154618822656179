<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Mittente della spedizione</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">
  <!-- (keyup)="filterList()" -->
  <div class="row mt-2 bg-light p-4 border">
    <div class="col-12">
      <label>Cerca anagrafica mittente</label>
      <div><span class="small text-muted">Digita almeno 4 caratteri</span></div>
      <input type="text" class="form-control mt-3" placeholder="Inizia a digitare il nome" [(ngModel)]="oAction.addressProfile" (keyup)="filterList()">
    </div>
    <div class="list-group">
      <div class="list-group-item list-group-item-action pointer" *ngFor="let sender of aAvailableSenders" (click)="selectSender(sender)">
        <div class="d-flex align-items-center">
          <div class="me-1 col-10">
            {{sender.azienda}} {{sender.rif_cognome}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2 mb-2">
    <div class="col-12 col-md-6 mt-3">
      <label>Azieda (*)</label>
      <input type="text" class="form-control" placeholder="Azienda o Cognome Nome" [(ngModel)]="oShipData.mit_agency">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Riferimento interno (*)</label>
      <input type="text" class="form-control" placeholder="Nome azieda" [(ngModel)]="oShipData.mit_reference">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Telefono (*)</label>
      <input type="text" class="form-control" placeholder="Recapito telefonico" [(ngModel)]="oShipData.mit_phone">
    </div>
    <div class="col-12 col-md-6 mt-3" *ngIf="vrshippingService.userData.type == 'A'">
      <label>Email (*)</label>
      <input type="text" class="form-control" placeholder="Email di riferimento" [(ngModel)]="oShipData.mit_email">
    </div>
  </div>

  <div class="row border-top mt-3 mb-2">
    <div class="col-12 col-md-6 mt-3">
      <label>Nazione (*)</label>
      <select class="form-control" [(ngModel)]="oShipData.mit_state">
        <option value="--">-- Seleziona nazione --</option>
        <option *ngFor="let nazione of vrshippingService.settingsData.countries" [value]="nazione.iso">{{nazione.printable_name}} ({{nazione.iso}})</option>
      </select>
    </div>
    <div class="col-12 col-md-6 mt-3" *ngIf="oShipData.mit_state == 'IT'">
      <label>Provincia (*)</label>
      <select class="form-control" [(ngModel)]="oShipData.mit_us_prov">
        <option value="--">-- Seleziona provincia --</option>
        <option *ngFor="let prov of vrshippingService.settingsData.provinces" [value]="prov.sigla">{{prov.prov_desc}} ({{prov.sigla}})</option>
      </select>
    </div>
    <div class="col-12 col-md-6 mt-3" *ngIf="oShipData.mit_state == 'US'">
      <label>Stato US (*)</label>
      <select class="form-control" [(ngModel)]="oShipData.mit_us_prov">
        <option value="--">-- Seleziona stato --</option>
        <option *ngFor="let state of vrshippingService.settingsData.us_states" [value]="state.state_code">{{state.state}} ({{state.state_code}})</option>
      </select>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-12 col-md-6 mt-3">
      <label>Città (*)</label>
      <input type="text" class="form-control" placeholder="Paese/Città" [(ngModel)]="oShipData.mit_city">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>CAP/ZIP CODE (*)</label>
      <input type="text" class="form-control" placeholder="" [(ngModel)]="oShipData.mit_zip">
    </div>
  </div>

  <div class="row mt-3 mb-2 align-items-center">
    <div class="col-12 col-md-4 mt-3">
      <label>Indirizzo (*)</label>
      <input type="text" class="form-control" placeholder="Paese/Città" [(ngModel)]="oShipData.mit_address">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>Indirizzo 2</label>
      <input type="text" class="form-control" placeholder="" [(ngModel)]="oShipData.mit_address_2">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="statusCheck" [(ngModel)]="oAction.status">
        <label class="form-check-label" for="statusCheck">
          Salva in rubrica mittenti
        </label>
      </div>
    </div>
  </div>

  <div class="row mb-2 mt-3">
    <div class="col-12 small text-muted text-center">
      (*) Campi obbligatori
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" [disabled]="!checkSenderConsistency()" (click)="saveProfile()">CONFERMA</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
