<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>
    PROFILO <span *ngIf="oProfile.id == 0">NUOVO</span> {{oProfile.azienda}}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

  <div class="row mb-2">
    <div class="col-12 col-md-4">
      <label>Denominazione</label>
      <input type="text" class="form-control" placeholder="Denominazione aziendale del cliente" maxlength="200" [(ngModel)]="oProfile.azienda">
    </div>
    <div class="col-12 col-md-4">
      <label>Riferimento contatto (Cognome) (*)</label>
      <input type="text" class="form-control" placeholder="Nominativo riferimento" maxlength="250" [(ngModel)]="oProfile.rif_cognome">
    </div>
    <div class="col-12 col-md-4">
      <label>Nome contatto</label>
      <input type="text" class="form-control" placeholder="Nominativo riferimento" maxlength="250" [(ngModel)]="oProfile.rif_nome">
    </div>

    <div class="col-12 col-md-6 mt-3">
      <label>Email (*)</label>
      <input type="text" class="form-control" placeholder="Indirizzo e-mail" maxlength="250" [(ngModel)]="oProfile.rif_email">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Recapito telefonico (*)</label>
      <input type="text" class="form-control" placeholder="Cellulare / Fisso" maxlength="30" [(ngModel)]="oProfile.tel">
    </div>

    <div class="col-12 col-md-4 mt-3">
      <label>Nazione (*)</label>
      <select class="form-control" [(ngModel)]="oProfile.nazione">
        <option value="--">-- Seleziona nazione --</option>
        <option *ngFor="let nazione of vrshippingService.settingsData.countries" [value]="nazione.iso">{{nazione.printable_name}} ({{nazione.iso}})</option>
      </select>
    </div>
    <div class="col-12 col-md-4 mt-3" *ngIf="oProfile.nazione == 'IT'">
      <label>Provincia (*)</label>
      <select class="form-control" [(ngModel)]="oProfile.prov">
        <option value="--">-- Seleziona provincia --</option>
        <option *ngFor="let prov of vrshippingService.settingsData.provinces" [value]="prov.sigla">{{prov.prov_desc}} ({{prov.sigla}})</option>
      </select>
    </div>
    <div class="col-12 col-md-4 mt-3" *ngIf="oProfile.nazione == 'US'">
      <label>Stato US (*)</label>
      <select class="form-control" [(ngModel)]="oProfile.usstate">
        <option value="--">-- Seleziona stato --</option>
        <option *ngFor="let state of vrshippingService.settingsData.us_states" [value]="state.state_code">{{state.state}} ({{state.state_code}})</option>
      </select>
    </div>
    <div class="col-12 col-md-4 mt-3" *ngIf="oProfile.nazione !== 'IT' && oProfile.nazione !== 'US'">
      <label>Provincia (*)</label>
      <input type="text" placeholder="Provincia" maxlength="150" class="form-control" [(ngModel)]="oProfile.prov">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>Città/Comune (*)</label>
      <input type="text" placeholder="Comune" maxlength="150" class="form-control" [(ngModel)]="oProfile.citta">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>CAP (*)</label>
      <input type="text" placeholder="CAP/ZIP CODE" maxlength="150" class="form-control" [(ngModel)]="oProfile.cap">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>Indirizzo (*)</label>
      <input type="text" placeholder="Indirizzo" maxlength="150" class="form-control" [(ngModel)]="oProfile.indirizzo">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>Indirizzo (altro)</label>
      <input type="text" placeholder="Info aggiuntive indirizzo" maxlength="150" class="form-control" [(ngModel)]="oProfile.indirizzo2">
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" [disabled]="checkProfileConsistency()" (click)="saveProfile()">Salva</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
