<app-loader *ngIf="bLoadingData"></app-loader>

<div class="container mt-3 p-2 mb-4">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <div class="box_contact_data" (click)="editProfile(0)">
        <div class="add_content">
          <i class="fa fa-plus" aria-hidden="true"></i><br />
          Aggiungi indirizzo
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" *ngFor="let profile of aSenders">
      <div class="box_contact_data">
        <div class="details_status">
          Stato indirizzo:
          <span class="text-success" *ngIf="profile.stato == 'A'">ATTIVO</span>
          <span class="text-danger" *ngIf="profile.stato == 'B'">BLOCCATO</span>
        </div>
        <div style="border-bottom: solid 1px #c0c0c0;"></div>
        <div class="details_content">
          <span *ngIf="profile.azienda !== ''"><strong>{{profile.azienda}}</strong><br /></span>
          {{profile.rif_cognome}}<br />
          {{profile.indirizzo}}<br />
          <span *ngIf="profile.indirizzo2 !== ''">{{profile.indirizzo2}}<br /></span>
          {{profile.cap}} {{profile.citta}}<br />
          <span *ngIf="profile.nazione == 'US'">{{profile.usstate}} {{profile.nazione}}</span>
          <span *ngIf="profile.nazione !== 'US'">{{profile.prov}} {{profile.nazione}}</span><br />
          Telefono: {{profile.tel}}<br />
          Email: {{profile.rif_email}}
        </div>
        <div class="details_command">
          <button class="btn btn-primary btn-sm me-2" (click)="editProfile(profile.id)">Modifica</button>

          <button class="btn btn-primary btn-sm me-2" *ngIf="profile.stato == 'A'" (click)="saveProfile(profile, 'B')">Blocca</button>
          <button class="btn btn-primary btn-sm me-2" *ngIf="profile.stato == 'B'" (click)="saveProfile(profile, 'A')">Attiva</button>

          <button class="btn btn-primary btn-sm me-2" (click)="saveProfile(profile, 'D')">Cancella</button>

        </div>
      </div>
    </div>
  </div>
</div>


