import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {countryData, shipData, shipPackageData} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-ship-package',
  templateUrl: './ship-package.component.html',
  styleUrls: ['./ship-package.component.scss']
})
export class ShipPackageComponent implements OnInit {

  @Input() oShipData: shipData = this.vrshippingService.getNewshipData();
  @Output() saveAnswer = new EventEmitter<shipData>();

  public bLoadingData: boolean = false;
  public oPackage: shipPackageData = this.vrshippingService.getNewShipPackageData();
  public aEuRegionCountries: Array<countryData> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal, public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.aEuRegionCountries = this.vrshippingService.settingsData.countries.filter((x: countryData) => x.eu_region);
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  checkEuRegion(){
    let aCountry: Array<countryData> = [];
    aCountry = this.aEuRegionCountries.filter(x=> x.iso == this.oShipData.dest_state);
    if (aCountry && aCountry.length > 0) return true;

    return false;
  }

  checkPackageConsistency(){
    if(this.oShipData.packages.length == 0) return false;
    if(!this.oShipData.date || this.oShipData.date == '') return false;
    if(this.oShipData.general_desc == '') return false;
    if(!this.checkEuRegion() && this.oShipData.type == 'NON_DOCUMENTS'){
      if(this.oShipData.duty_declared == 0) return false;
    }
    return true;
  }

  addPackage() {

    let total_weight: number = 0;

    this.oPackage.weight_vol = (this.oPackage.height * this.oPackage.length * this.oPackage.width) / 5000;
    this.oPackage.weight = this.oPackage.weight_declared;
    if(this.oPackage.weight_vol > this.oPackage.weight_declared) this.oPackage.weight = this.oPackage.weight_vol;
    this.oPackage.ref = this.oShipData.client_reference;

    this.oShipData.packages.push(this.oPackage);
    this.oShipData.packages.map((pack, index) => {
      pack.number = index + 1;
      total_weight += pack.weight;
      /*if(pack.weight > pack.weight_vol) total_weight += pack.weight;
      if(pack.weight < pack.weight_vol) total_weight += pack.weight_vol;*/
    });
    this.oShipData.total_weight = total_weight;
    this.oPackage = this.vrshippingService.getNewShipPackageData();
  }

  removePackage(oPackage: shipPackageData) {
    let total_weight: number = 0;

    this.oShipData.packages = this.oShipData.packages.filter(x=>x.number !== oPackage.number);
    this.oShipData.packages.map((pack, index) => {
      pack.number = index + 1;
      total_weight += pack.weight;
    });
    this.oShipData.total_weight = total_weight;
  }

  savePackageData() {
    this.saveAnswer.emit(this.oShipData);
  }

}
