import { Component, OnInit } from '@angular/core';
import { userData } from 'src/app/interfaces/vrs_interfaces';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public userData: userData = this.vrshippingService.getNewUserData();
  public oUserLogin = {
    username: "",
    psw: ""
  }

  constructor(private vrshippingService: VrshippingService, private modalService: NgbModal,
              private translateService: TranslateService) { }

  ngOnInit(): void {
    this.userData = this.vrshippingService.getNewUserData();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  loginVRshippingUser() {
    // TODO
    if(this.oUserLogin.username == '' || this.oUserLogin.psw == '') {
      //this.openDialog('danger', 'Attenzione: compila tutti i dati.');
    } else {
      // Login call
      this.vrshippingService.postLoginUserDB(this.oUserLogin).subscribe(login_response => {

          if(login_response.user && login_response.user.error) {
            //Error
            //this.openDialog('danger', 'Attenzione: nome utente o password errati');
            this.vrshippingService.bLoggedIn = false;
            this.openDialog('danger', "ATTENZIONE: nome utente e password errati: " + login_response.user.error);
          } else {
            this.userData = login_response.user.data;
            this.vrshippingService.setUserLoginData(login_response.user.data);
            this.vrshippingService.bLoggedIn = true;
            window.scroll(0,0);
          }

        },
        error => {
          // API call error
          console.log(error);
        });

    }
  }

}
