import {Component, Input, OnInit} from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {courierServiceAvailableData, shipData} from "../../interfaces/vrs_interfaces";

@Component({
  selector: 'app-courier-dhl-tracking',
  templateUrl: './courier-dhl-tracking.component.html',
  styleUrls: ['./courier-dhl-tracking.component.scss']
})
export class CourierDhlTrackingComponent implements OnInit {

  @Input() oShip: shipData = this.vrshippingService.getNewshipData();
  public bLoadingData: boolean = false;
  public bErrorLoadingData: boolean = false;
  public oTrackingResponse: any = null;
  public aPackStatus: Array<any> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.dhlTrackingRequest();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  dhlTrackingRequest() {

    this.bLoadingData = true;
    this.bErrorLoadingData = false;

    let obj: any = {ship_id: this.oShip.ship_id }

    this.vrshippingService.postDHLTrackingRequestDB(obj, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.dhl && get_response.dhl.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il tracking: ' + JSON.stringify(get_response.dhl.error));
          this.bErrorLoadingData = true;
        } else {

          if(get_response.dhl.data){
            this.oTrackingResponse = get_response.dhl.data.trackingResponse.TrackingResponse;
            if(this.oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem){
              if(this.oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.Status.ActionStatus !== 'Success') this.bErrorLoadingData = true;
              if(!this.bErrorLoadingData){
                // Get packages info
                if(Array.isArray(this.oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.Pieces.PieceInfo.ArrayOfPieceInfoItem)){
                  this.aPackStatus = this.oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.Pieces.PieceInfo.ArrayOfPieceInfoItem;
                }
                if(!Array.isArray(this.oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.Pieces.PieceInfo.ArrayOfPieceInfoItem)){
                  this.aPackStatus.push(this.oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.Pieces.PieceInfo.ArrayOfPieceInfoItem);
                }

                this.aPackStatus.map(x => {
                  if(!x.PieceEvent || !x.PieceEvent.ArrayOfPieceEventItem) x.PieceEvent.ArrayOfPieceEventItem = [];
                  if(x.PieceEvent.ArrayOfPieceEventItem && !Array.isArray(x.PieceEvent.ArrayOfPieceEventItem)) x.PieceEvent.ArrayOfPieceEventItem = [];
                });

              }
            }
          }
        }

        this.bLoadingData = false;
      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
        this.bErrorLoadingData = true;
      }
    );
  }

}
