<div class="modal-header">
</div>
<div class="modal-body">

  <div class="d-flex justify-content-center align-items-center flex-column py-3">
    <ng-container *ngIf="type=='danger'">
      <div class="fas fa-times-circle text-danger fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='warning'">
      <div class="fas fa-exclamation-triangle text-warning fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='success'">
      <div class="fas fa-check-circle text-success fa-3x mb-3" ></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='confirm'">
      <div class="fas fa-question-circle text-info fa-3x my-2"></div>
      <div class="text-center">{{message}}</div>
    </ng-container>

    <ng-container *ngIf="type=='confirmBRT'">
      <div class="fas fa-question-circle text-info fa-3x my-2"></div>
      <div class="text-center">{{message}}</div>
      <div class="mt-3 mb-2">
        <div class="alert alert-warning">
          <h4>SE LA SPEDIZIONE PREVEDE DEI PALLET</h4>
          Indicare le informazioni aggiuntive necessarie per organizzare il ritiro.
          <div class="d-flex flex-wrap mt-3">
            <div class="mt-2 me-2">
              <label>Numero di pallet EPAL</label>
              <input type="number" class="form-control" [(ngModel)]="brtOtherData.pallet_EPAL">
            </div>
            <div class="mt-2 me-2">
              <label>Numero di pallet EUR</label>
              <input type="number" class="form-control" [(ngModel)]="brtOtherData.pallet_EUR">
            </div>
            <div class="mt-2 me-2">
              <label>Necessaria sponda idraulica?</label>
              <select class="form-control" [(ngModel)]="brtOtherData.pallet_tail_lift">
                <option value=" ">NO</option>
                <option value="S">SI</option>
              </select>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

  </div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end" *ngIf="type=='confirm' || type=='confirmBRT'">
    <button type="button" class="btn btn-success me-2" *ngIf="type !== 'confirmBRT'" (click)="getConfirmResponse(true)">CONFERMA</button>
    <button type="button" class="btn btn-success me-2" *ngIf="type == 'confirmBRT'" (click)="getConfirmResponseBRT()">CONFERMA</button>
    <button type="button" class="btn btn-secondary" (click)="getConfirmResponse(false)">ANNULLA</button>
  </div>
  <button type="button" class="btn btn-secondary" *ngIf="type!='confirm'" (click)="activeModal.close('Close click')">CHIUDI</button>
</div>
