import { Component, OnInit } from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {userProfileData} from "../../interfaces/vrs_interfaces";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserEditComponent} from "../../components/user-edit/user-edit.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public bLoadingData: boolean = false;

  public aUsers: Array<userProfileData> = [];
  public oFilter: any = {
    reference: "",
    nome: "",
    email: "",
    stato: "",
    user_count: 0
  }

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    if(!this.vrshippingService.bLoggedIn) this.router.navigate(['home']);
    this.getUserList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  filterList(aoUser: Array<userProfileData>) {

    if (aoUser && aoUser.length > 0) {
      if (this.oFilter.nome !== '') aoUser = aoUser.filter(x => x.nome.toLowerCase().indexOf(this.oFilter.nome.toLowerCase()) !== -1 || x.cognome.toLowerCase().indexOf(this.oFilter.nome.toLowerCase()) !== -1);
      if (this.oFilter.reference !== '') aoUser = aoUser.filter(x => x.reference.toLowerCase().indexOf(this.oFilter.reference.toLowerCase()) !== -1);
      if (this.oFilter.email !== '') aoUser = aoUser.filter(x => x.email.toLowerCase().indexOf(this.oFilter.email.toLowerCase()) !== -1);
      if (this.oFilter.stato !== '') aoUser = aoUser.filter(x => x.stato == this.oFilter.stato);
    }

    return aoUser;

  }

  getUserList() {

    this.bLoadingData = true;

    this.vrshippingService.getUsersListDB(this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.users && get_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare la lista utenti' + ': ' + JSON.stringify(get_response.users.error));
        } else {
          this.aUsers = get_response.users;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + JSON.stringify(error));
        this.bLoadingData = false;
      });

  }

  editUser(id_user: number) {
    const modalRef = this.modalService.open(UserEditComponent, { size: 'xl' });
    modalRef.componentInstance.id_user = id_user;
    modalRef.componentInstance.edit_type = 'gest';
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        this.getUserList();
      }
    });
  }

}
