<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Tracking DHL</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">

      <div class="alert alert-danger" *ngIf="bErrorLoadingData">
        <h3>Errore nel caricamento dei dati</h3>
        Il sistema non è riuscito ad identificare la tua spedizione, contatta assistenza per maggiori informazioni.
      </div>

      <section id="dataDetails" *ngIf="!bErrorLoadingData && oTrackingResponse">

        <div class="d-flex flex-wrap mt-2 text-center">
          <div class="flex-fill col-4">
            <div>{{oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.OriginServiceArea.Description}}</div>
            <div class="small text-muted">{{oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ShipperName}}</div>
          </div>
          <div class="flex-fill col-4">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <div>
              {{oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.Weight}} Kg |
              Pacchi: {{oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.Pieces}}
            </div>
          </div>
          <div class="flex-fill col-4">
            <div>{{oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.DestinationServiceArea.Description}}</div>
            <div class="small text-muted">{{oTrackingResponse.AWBInfo.ArrayOfAWBInfoItem.ShipmentInfo.ConsigneeName}}</div>
          </div>
        </div>
        <hr />

        <div class="mt-3" *ngFor="let pack of aPackStatus">
          <h5>Pacco {{pack.PieceDetails.PieceNumber}} - {{pack.PieceDetails.LicensePlate}}</h5>

          <div class="alert alert-warning" *ngIf="pack.PieceEvent.ArrayOfPieceEventItem.length == 0">
            <h5>Nessuna informazione disponibile sulla spedizione</h5>
          </div>

          <div class="d-flex flex-wrap">
            <div class="border mt-2 mb-2 me-2 bg-primary text-center" [class]="{'bg-success': step.ServiceEvent.EventCode=='OK'}" style="--bs-bg-opacity: .1;" *ngFor="let step of pack.PieceEvent.ArrayOfPieceEventItem">
              <div class="p-3">
                {{step.Date | date: 'dd/MM/yyyy'}} {{step.Time}}
                <div>{{step.ServiceArea.Description}}</div>
                <div>{{step.ServiceEvent.Description}}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
