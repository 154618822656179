import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {addressProfile, countryData, shipData} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-ship-receiver',
  templateUrl: './ship-receiver.component.html',
  styleUrls: ['./ship-receiver.component.scss']
})
export class ShipReceiverComponent implements OnInit {

  @Input() oShipData: shipData = this.vrshippingService.getNewshipData();
  @Output() saveAnswer = new EventEmitter<shipData>();

  public oProfile: addressProfile = this.vrshippingService.getNewAddressProfile();

  public bLoadingData: boolean = false;
  public aReceivers: Array<addressProfile> = [];
  public aAvailableReceivers: Array<addressProfile> = [];
  public oAction: any = {
    status: false,
    addressProfile: ""
  }

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal, public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.getReceiverList();
    if(this.vrshippingService.bPallet) this.setPalletShipping();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  setPalletShipping() {
    this.oShipData.dest_state = "IT";
  }

  getReceiverList () {

    this.bLoadingData = true;

    this.vrshippingService.getAddressProfileListDB(this.vrshippingService.userData.token, 'receiver').subscribe(get_response => {

        if(get_response.profiles && get_response.profiles.error) {
          //Error
          this.openDialog('danger', "Errore nel caricamento dei destinatari: " + get_response.profiles.error);
        } else {

          this.aReceivers = get_response.profiles;
        }
        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', "Errore imprevisto del server: " + error.message);
        this.bLoadingData = false;
      });
  }

  checkReceiverConsistency() {

    if(this.oShipData.dest_reference == '') return false;
    if(this.oShipData.dest_agency == '') return false;
    if(this.oShipData.dest_phone == '') return false;
    if(this.oShipData.dest_state == '--') return false;
    if(this.oShipData.dest_city == '') return false;
    if(this.oShipData.dest_address == '') return false;
    if(this.oShipData.dest_zip == '') return false;
    if(this.oShipData.dest_state == 'IT' || this.oShipData.dest_state == 'US'){
      if(this.oShipData.dest_us_prov == '--') return false;
    }

    return true;

  }

  checkNoCapCountry(){
    let aCountry: Array<countryData> = [];
    aCountry = this.vrshippingService.settingsData.countries.filter((x: countryData) => x.iso == this.oShipData.dest_state && x.no_cap == 1);
    if (aCountry && aCountry.length > 0){
      this.oShipData.dest_zip = "";
      return true;
    }

    return false;
  }

  filterList(){
    this.aAvailableReceivers = [];
    if(this.oAction.addressProfile && this.oAction.addressProfile.length >= 3) {
      this.aAvailableReceivers = this.aReceivers.filter(x => x.azienda.toLowerCase().indexOf(this.oAction.addressProfile.toLowerCase()) !== -1 || x.rif_cognome.toLowerCase().indexOf(this.oAction.addressProfile.toLowerCase()) !== -1);
    }
  }

  selectReceiver(profile: addressProfile){

    this.oProfile.id = 0;
    this.oShipData.cod_dest = profile.id;
    this.oShipData.dest_agency = profile.azienda;
    this.oShipData.dest_reference = profile.rif_cognome;
    this.oShipData.dest_email = "spedizioni@veronasped.it";
    this.oShipData.dest_phone = profile.tel;
    this.oShipData.dest_state = profile.nazione;
    if(this.oShipData.dest_state == 'IT') this.oShipData.dest_us_prov = profile.prov;
    if(this.oShipData.dest_state == 'US') this.oShipData.dest_us_prov = profile.usstate;
    this.oShipData.dest_city = profile.citta;
    this.oShipData.dest_zip = profile.cap;
    this.oShipData.dest_address = profile.indirizzo;
    this.oShipData.dest_address_2 = profile.indirizzo2;

    this.oAction.addressProfile = "";
    this.aAvailableReceivers = [];

  }

  insertProfile() {

    this.bLoadingData = true;

    this.oProfile.id = 0;
    this.oProfile.azienda = this.oShipData.dest_agency;
    this.oProfile.rif_cognome = this.oShipData.dest_reference;
    this.oProfile.rif_nome = "";
    this.oProfile.rif_email = this.oShipData.dest_email;
    this.oProfile.tel = this.oShipData.dest_phone;
    this.oProfile.nazione = this.oShipData.dest_state;
    this.oProfile.prov = this.oShipData.dest_us_prov;
    this.oProfile.usstate = this.oShipData.dest_us_prov;
    this.oProfile.citta = this.oShipData.dest_city;
    this.oProfile.cap = this.oShipData.dest_zip;
    this.oProfile.indirizzo = this.oShipData.dest_address;
    this.oProfile.indirizzo2 = this.oShipData.dest_address_2;

    this.vrshippingService.postAddressProfileInsertDB(this.oProfile, 'receiver', this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.profiles && insert_response.profiles.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + JSON.stringify(insert_response.profiles.error));
        } else {
          this.openDialog('success', 'Profilo inserito con successo!');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  saveProfile(){
    if(this.oAction.status) this.insertProfile();
    this.saveAnswer.emit(this.oShipData);
  }

}
