import { Component, OnInit } from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {
  courierData,
  listinoData,
  listinoShipData,
  listinoShipDetailsData, listinoShipUpdateData,
  listinoZoneData
} from "../../interfaces/vrs_interfaces";
import {CourierEditComponent} from "../../components/courier-edit/courier-edit.component";
import {ListDuplicatorComponent} from "../../components/list-duplicator/list-duplicator.component";
import {ListShipUpgradeComponent} from "../../components/list-ship-upgrade/list-ship-upgrade.component";

@Component({
  selector: 'app-list-page',
  templateUrl: './list-page.component.html',
  styleUrls: ['./list-page.component.scss']
})
export class ListPageComponent implements OnInit {

  public bLoadingData: boolean = false;

  public aList: Array<listinoData> = [];
  public aZoneList: Array<listinoZoneData> = [];
  public aListShip: listinoShipData = this.vrshippingService.getNewListinoShipData();
  public aCourierList: Array<courierData> = [];

  public oListinoShip: listinoShipDetailsData = this.vrshippingService.getNewListinoShipDetailsData();
  public oFilter: any = {
    courier_selected: "",
    list_code: "",
    zone_code: '',
    record_number: 0
  }
  public oLastUpdate: listinoShipUpdateData = this.vrshippingService.getNewListinoShipUpdateData();

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getCourierList ();
    this.getListinoList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  selectCourierFilter(courier_code: string){
    this.oFilter.courier_selected = courier_code;
    this.getListinoShipList(courier_code);
  }

  getCourierList () {

    this.bLoadingData = true;

    this.vrshippingService.getCourierListDB(this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.couriers && get_response.couriers.error) {
          //Error
          this.openDialog('danger', "Errore nel caricamento dei corrieri: " + get_response.couriers.error);
        } else {
          this.aCourierList = get_response.couriers;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', "Errore imprevisto del server: " + error.message);
      });
  }

  getListinoList() {

    this.bLoadingData = true;

    this.vrshippingService.getListinoListDB(this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.lists && get_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i listini' + ': ' + get_response.lists.error);
        } else {
          this.aList = get_response.lists;
          this.aZoneList = [];
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getListinoShipList(courier_code: string) {

    this.bLoadingData = true;

    this.vrshippingService.getListinoShipListDB(courier_code, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.lists && get_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i listini' + ': ' + get_response.lists.error);
        } else {
          this.aListShip = get_response.lists;
          //this.oFilter.record_number = this.aListShip.length;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  listCodeChange(){

    let selectedList: Array<listinoData> = [];
    let i: number = 1;
    let obj: any = {id:0, description: ''};
    this.aZoneList = [];
    this.oFilter.zone_code = '';
    selectedList = this.aList.filter(x=> x.codice == this.oFilter.list_code);

    if(selectedList && selectedList.length>0){

      while (i <= selectedList[0].nrZone){
        obj = {id: i, description: 'Zona ' + (i)};
        this.aZoneList.push(obj);
        i+=1;
      }

    }

    // Set last update DATA
    this.oLastUpdate = this.vrshippingService.getNewListinoShipUpdateData();
    if(this.aListShip.list_update && this.aListShip.list_update.length > 0){
      let updateFound: Array<listinoShipUpdateData> = [];
      updateFound = this.aListShip.list_update.filter(x => x.list_code == selectedList[0].codice);
      if(updateFound.length > 0) this.oLastUpdate = updateFound[0];
    }

  }

  filterListShip(list: Array<listinoShipDetailsData>){

    if(this.oFilter.list_code !== '') list = list.filter(x=> x.cod_vrsped == this.oFilter.list_code);
    if(this.oFilter.zone_code !== '') list = list.filter(x=> x.zone == Number(this.oFilter.zone_code));
    this.oFilter.record_number = list.length;

    return list;

  }

  modifyListinoShip(type: string, oListShip: listinoShipDetailsData) {

    this.bLoadingData = true;

    if(type == 'insert') {
      oListShip.zone = this.oFilter.zone_code;
      oListShip.cod_vrsped = this.oFilter.list_code;
      oListShip.courier_code = this.oFilter.courier_selected;
    }

    this.vrshippingService.postListinoShipAPIDB(oListShip, type, this.vrshippingService.userData.token).subscribe(mod_response => {

        if(mod_response.lists && mod_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile aggiornare il listino: ' + JSON.stringify(mod_response.lists.error));
        } else {
          this.getListinoShipList(this.oFilter.courier_selected);
          this.openDialog('success', 'Listino aggiornato correttamente');
          if(type == 'insert') this.oListinoShip = this.vrshippingService.getNewListinoShipDetailsData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + JSON.stringify(error));
        this.bLoadingData = false;
      }
    );

  }

  openDuplicator(courier_code: string) {
    const modalRef = this.modalService.open(ListDuplicatorComponent, { size: 'xl' });
    modalRef.componentInstance.courier_code = courier_code;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        this.getCourierList();
      }
    });
  }

  openUpgrade(courier_code: string) {
    const modalRef = this.modalService.open(ListShipUpgradeComponent, { size: 'xl' });
    modalRef.componentInstance.courier_code = courier_code;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        this.getCourierList();
        this.getListinoShipList(this.oFilter.courier_selected);
      }
    });
  }

  confirmDeleteAllRecord(courier_code: string) {
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'xl' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Attenzione, eliminare tutti i prezzi del listino " + courier_code;
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        this.oListinoShip.courier_code = courier_code;
        this.deleteAllListinoShip(this.oListinoShip);
      }
    });
  }

  deleteAllListinoShip(oListShip: listinoShipDetailsData) {

    this.bLoadingData = true;

    this.vrshippingService.postListinoShipAPIDB(oListShip, 'delete', this.vrshippingService.userData.token, true).subscribe(mod_response => {

        if(mod_response.lists && mod_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile aggiornare il listino: ' + JSON.stringify(mod_response.lists.error));
        } else {
          this.oListinoShip = this.vrshippingService.getNewListinoShipDetailsData();
          this.getListinoShipList(this.oFilter.courier_selected);
          this.openDialog('success', 'Listino eliminato correttamente');
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + JSON.stringify(error));
        this.bLoadingData = false;
      }
    );

  }

}
