import { Component, OnInit } from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {formatDate} from "@angular/common";
import {shipData} from "../../interfaces/vrs_interfaces";
import {CourierDhlTrackingComponent} from "../../components/courier-dhl-tracking/courier-dhl-tracking.component";
import {CourierBrtTrackingComponent} from "../../components/courier-brt-tracking/courier-brt-tracking.component";
import {CourierFedexTrackingComponent} from "../../components/courier-fedex-tracking/courier-fedex-tracking.component";

@Component({
  selector: 'app-shipping-archive',
  templateUrl: './shipping-archive.component.html',
  styleUrls: ['./shipping-archive.component.scss']
})
export class ShippingArchiveComponent implements OnInit {

  public bLoadingData: boolean = false;
  public oFilter: any = {
    from: null,
    to: null,
    courier: '--',
    sender: "",
    ship_id: ""
  }
  public aoShipList: Array<shipData> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    let from: Date = new Date();
    let to: Date = new Date();
    from.setDate(to.getDate() - 15);
    this.oFilter.to = formatDate(to, 'yyyy-MM-dd', 'en_US');
    this.oFilter.from = formatDate(from, 'yyyy-MM-dd', 'en_US');
    this.getShipList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getShipList() {

    this.bLoadingData = true;

    this.vrshippingService.getShipDataListDB(this.oFilter.from, this.oFilter.to, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.ship && get_response.ship.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare le spedizioni' + ': ' + get_response.ship.error);
        } else {
          this.aoShipList = get_response.ship;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  filterShipList(){
    this.getShipList();
  }

  filterList(aList: Array<shipData>) {

    aList = this.aoShipList;

    // Apply filter data
    if(this.oFilter.courier !== '--') aList = aList.filter(x => x.courier_code == this.oFilter.courier);
    if(this.oFilter.sender !== '') aList = aList.filter(x => x.mit_agency.toLowerCase().indexOf(this.oFilter.sender.toLowerCase()) !== -1 );
    if(this.oFilter.ship_id !== '') aList = aList.filter(x => x.ship_id == this.oFilter.ship_id);

    return aList;

  }

  downloadPdf(base64String: string, fileName: string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  onClickDownloadPdf(ship: shipData){
    if(ship.courier_code == 'DHL') this.downloadPdf(ship.pdf_base64,ship.courier_code + "_shipment_label_"+ ship.ship_id + ".pdf");
    if(ship.courier_code !== 'DHL') {
      ship.packages.forEach(pack => {
        this.downloadPdf(pack.stream,ship.courier_code + "_" + pack.tracking + "_"+ ship.ship_id + ".pdf");
      });
    }
  }

  trackingData(ship: shipData){

    if(ship.courier_code == 'DHL') {
      const modalRef = this.modalService.open(CourierDhlTrackingComponent, { size: 'xl' });
      modalRef.componentInstance.oShip = ship;
    }

    if(ship.courier_code == 'BRT') {
      const modalRef = this.modalService.open(CourierBrtTrackingComponent, { size: 'xl' });
      modalRef.componentInstance.oShip = ship;
    }

    if(ship.courier_code == 'FEDEX') {
      const modalRef = this.modalService.open(CourierFedexTrackingComponent, { size: 'xl' });
      modalRef.componentInstance.oShip = ship;
      //window.open('https://www.fedex.com/apps/fedextrack/?action=track&tracknumbers=' + ship.packages[0].tracking, '_blank');
    }

  }

  trackingDataFedex(trackID: string){

    let ship: shipData = this.vrshippingService.getNewshipData();
    ship.ship_id = trackID;

    const modalRef = this.modalService.open(CourierFedexTrackingComponent, { size: 'xl' });
    modalRef.componentInstance.oShip = ship;

  }

  deleteShipRequest(ship: shipData) {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Confermi di voler eliminate questa spedizione: " + ship.id + "?";
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {

        this.bLoadingData = true;

        this.vrshippingService.postShipDataDeleteDB(ship, this.vrshippingService.userData.token).subscribe(update_response => {

            if(update_response.ship && update_response.ship.error) {
              //Error
              this.openDialog('danger', 'Attenzione, impossibile eliminare la spedizione' + ': ' + update_response.ship.error);
            } else {
              this.getShipList()
            }

            this.bLoadingData = false;
          },
          error => {
            // API call error
            console.log(error);
            this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
            this.bLoadingData = false;
          }
        );
      }
    });




  }

  brtRegenerateFile(ship: shipData) {

    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Confermi di voler rigenerare il file TXT della spedizione: " + ship.ship_id + "?";
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {

        this.bLoadingData = true;

        this.vrshippingService.postBRTCreateFileDB(ship, this.vrshippingService.userData.token).subscribe(update_response => {

            if(update_response.ship && update_response.ship.error) {
              //Error
              this.openDialog('danger', 'Attenzione, impossibile generare il file' + ': ' + update_response.ship.error);
            } else {
              this.openDialog('success', 'File pickup generato con successo!');
            }

            this.bLoadingData = false;

          },
          error => {
            // API call error
            console.log(error);
            this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
            this.bLoadingData = false;
          }
        );
      }
    });




  }

}
