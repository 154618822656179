<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Aggiornamento del listino {{courier_code}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">
  <div class="row pt-2 pb-2">
    <div class="col-12 col-md-4">
      <label>Listino di riferimento</label>
      <select class="form-control mt-2" [(ngModel)]="oListShipUpgrade.list_code">
        <option value="">-- Seleziona listino --</option>
        <option [value]="list.codice" *ngFor="let list of aList">{{list.codice}} {{list.description}}</option>
      </select>
    </div>
    <div class="col-12 col-md-4">
      <label>Operatore</label>
      <select class="form-control mt-2" [(ngModel)]="oListShipUpgrade.operator">
        <option value="plus">Aumento</option>
        <option value="less">Sconto</option>
      </select>
    </div>
    <div class="col-12 col-md-4">
      <label>Percentuale %</label>
      <input type="number" class="form-control mt-2" [(ngModel)]="oListShipUpgrade.perc_value">
    </div>

    <div class="col-12 mt-5 border-top text-center">
      <button class="btn btn-xl btn-success mt-4" (click)="upgradeListinoShip()" [disabled]="oListShipUpgrade.list_code == ''">ELABORA DATI</button>
    </div>

    <div class="col-12 mt-5 border-top">
      <div class="d-flex flex-wrap">
        <div class="alert alert-success me-2 mt-2" *ngIf="aUpgradeResponseData.success">
          <h4>Record aggiornati: {{aUpgradeResponseData.success.length}}</h4>
        </div>
        <div class="alert alert-danger me-2 mt-2" *ngIf="aUpgradeResponseData.errors">
          <h4>Errori: {{aUpgradeResponseData.errors.length}}</h4>
          <div class="d-flex flex-wrap">
            <div class="border p-3 mt-2 me-2" *ngFor="let error_row of aUpgradeResponseData.errors">
              <div>Listino: {{error_row.cod_vrsped}}</div>
              <div>Peso min: {{error_row.peso_min}}</div>
              <div>Peso max: {{error_row.peso_max}}</div>
              <div>Zona: {{error_row.zone}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
