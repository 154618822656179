import {Component, Input, OnInit} from '@angular/core';
import {shipData} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-courier-fedex-tracking',
  templateUrl: './courier-fedex-tracking.component.html',
  styleUrls: ['./courier-fedex-tracking.component.scss']
})
export class CourierFedexTrackingComponent implements OnInit {

  @Input() oShip: shipData = this.vrshippingService.getNewshipData();
  public bLoadingData: boolean = false;
  public bErrorLoadingData: boolean = false;
  public oTrackingResponse: any = null;
  public aSteps: Array<any> = [];
  public aErrors: Array<any> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.fedexTrackingRequest();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  fedexTrackingRequest() {

    this.bLoadingData = true;
    this.bErrorLoadingData = false;

    this.vrshippingService.postFedexTrackingRequestDB(this.oShip, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.fedex && get_response.fedex.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il tracking: ' + JSON.stringify(get_response.fedex.error));
          this.bErrorLoadingData = true;
        } else {

          if(get_response.fedex.data){
            this.oTrackingResponse = get_response.fedex.data;
            if(this.oTrackingResponse.output){
              this.oTrackingResponse.output.completeTrackResults.forEach((item: any) => {
                item.trackResults.forEach((track: any) => {
                  if(track.error) {
                    let object = {
                      code: track.error.code,
                      message: track.error.message
                    }
                    this.aErrors.push(object);
                  }

                  if(!track.error){
                    track.dateAndTimes.forEach((step: any) => {
                      let object = {
                        date: step.dateTime,
                        message: step.type
                      }
                      this.aSteps.push(object);
                    });
                  }

                });
              })
            }
          }
        }

        this.bLoadingData = false;
      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
        this.bErrorLoadingData = true;
      }
    );
  }

}
