import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
