import { Component, OnInit } from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {
  countryData,
  courierServiceAvailableData,
  courierUserData,
  shipData,
  userProfileData
} from "../../interfaces/vrs_interfaces";
import {ListShipUpgradeComponent} from "../../components/list-ship-upgrade/list-ship-upgrade.component";
import {ShipSenderComponent} from "../../components/ship-sender/ship-sender.component";
import {ShipReceiverComponent} from "../../components/ship-receiver/ship-receiver.component";
import {ShipPackageComponent} from "../../components/ship-package/ship-package.component";
import {ActivatedRoute, UrlSegment} from "@angular/router";

@Component({
  selector: 'app-shipping-data',
  templateUrl: './shipping-data.component.html',
  styleUrls: ['./shipping-data.component.scss']
})
export class ShippingDataComponent implements OnInit {

  public bLoadingData: boolean = false;
  public bSenderData: boolean = false;
  public bReceiverData: boolean = false;
  public bPackageData: boolean = false;
  public bLoadService: boolean = false;

  public oShipData: shipData = this.vrshippingService.getNewshipData();
  public oUser: userProfileData = this.vrshippingService.getNewUserProfileData();
  public aEuRegionCountries: Array<countryData> = [];
  public aoUserCourier: Array<courierUserData> = [];
  public serviceSelected: courierServiceAvailableData = this.vrshippingService.getNewCourierServiceAvailableData();

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.initAppComponent();
    const currentUrl: UrlSegment[] = this.route.snapshot.url;
    if(currentUrl && currentUrl.length > 0 && currentUrl[0].path == 'pallet-service') this.vrshippingService.bPallet = true;
  }

  initAppComponent(){
    this.aEuRegionCountries = this.vrshippingService.settingsData.countries.filter((x: countryData) => x.eu_region);
    this.getUserProfile();
    this.getUserCourierList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getUserProfile() {

    this.bLoadingData = true;

    this.vrshippingService.getUsersListDB(this.vrshippingService.userData.token, this.vrshippingService.userData.id).subscribe(get_response => {

        if(get_response.users && get_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il profilo utente' + ': ' + get_response.users.error);
        } else {
          this.oUser = get_response.users[0];

          this.oShipData.client_reference = this.oUser.reference;
          this.oShipData.mit_agency = this.oUser.azienda;
          this.oShipData.mit_reference = this.oUser.cognome + ' ' + this.oUser.nome;
          this.oShipData.mit_phone = this.oUser.tel;
          this.oShipData.mit_state = this.oUser.nazione;
          this.oShipData.mit_city = this.oUser.citta;
          this.oShipData.mit_address = this.oUser.indirizzo;
          this.oShipData.mit_zip = this.oUser.cap;
          this.oShipData.mit_us_prov = this.oUser.prov;

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getUserCourierList(){

    this.vrshippingService.getUserCourierListDB(this.vrshippingService.userData.id, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.couriers && get_response.couriers.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i corrieri abilitati' + ': ' + get_response.couriers.error);
        } else {
          this.aoUserCourier = get_response.couriers;
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  checkShipSenderData() {

    this.bSenderData = false

    if(this.oShipData.mit_reference == '') return false;
    if(this.oShipData.mit_agency == '') return false;
    if(this.oShipData.mit_phone == '') return false;
    if(this.oShipData.mit_state == '--') return false;
    if(this.oShipData.mit_city == '') return false;
    if(this.oShipData.mit_address == '') return false;
    if(this.oShipData.mit_zip == '') return false;
    if(this.oShipData.mit_state == 'IT' || this.oShipData.mit_state == 'US'){
      if(this.oShipData.mit_us_prov == '') return false;
    }

    this.bSenderData = true;
    return true;
  }

  checkShipReceiverData() {

    this.bReceiverData = false

    if(this.oShipData.dest_reference == '') return false;
    if(this.oShipData.dest_agency == '') return false;
    if(this.oShipData.dest_phone == '') return false;
    if(this.oShipData.dest_state == '--') return false;
    if(this.oShipData.dest_city == '') return false;
    if(this.oShipData.dest_address == '') return false;
    if(this.oShipData.dest_zip == '') return false;
    if(this.oShipData.dest_state == 'IT' || this.oShipData.mit_state == 'US'){
      if(this.oShipData.dest_us_prov == '') return false;
    }

    this.bReceiverData = true;
    return true;

  }

  checkShipPackageData() {

    this.bPackageData = false;
    if(this.oShipData.packages.length == 0) return false;
    if(this.oShipData.hour == '') return false;
    if(this.oShipData.date == '') return false;

    this.bPackageData = true;
    return true;


  }

  checkEuRegion(){
    let aCountry: Array<countryData> = [];
    aCountry = this.aEuRegionCountries.filter(x=> x.iso == this.oShipData.dest_state);
    if (aCountry && aCountry.length > 0) return true;

    return false;
  }

  checkUserCourier(courier_code: string){
    let aCourier: Array<courierUserData> =  [];
    aCourier = this.aoUserCourier.filter(x => x.courier_code == courier_code);
    return aCourier.length > 0;
  }

  openSenderData(){

    if(!this.bLoadService) {
      const modalRef = this.modalService.open(ShipSenderComponent, { size: 'xl' });
      modalRef.componentInstance.oShipData = Object.assign({}, this.oShipData);
      modalRef.componentInstance.saveAnswer.subscribe((confirmation: shipData) => {
        if(confirmation) {
          this.oShipData = confirmation;
          modalRef.close();
          if(this.bSenderData && this.bReceiverData && this.bPackageData) this.loadCourierServices();
        }
      });
    }


  }

  openReceiverData(){

    if(!this.bLoadService) {
      const modalRef = this.modalService.open(ShipReceiverComponent, { size: 'xl' });
      modalRef.componentInstance.oShipData = Object.assign({}, this.oShipData);
      modalRef.componentInstance.saveAnswer.subscribe((confirmation: shipData) => {
        if(confirmation) {
          this.oShipData = confirmation;

          if(this.checkEuRegion()){
            this.oShipData.type = "DOCUMENTS";
            this.oShipData.duty_declared = 0;
            this.oShipData.duty_type = "DAP";
          }

          this.checkShipPackageData();

          if(this.bSenderData && this.bReceiverData && this.bPackageData) this.loadCourierServices();

          modalRef.close();
        }
      });
    }

  }

  openPackageData(){

    if(!this.bLoadService) {
      const modalRef = this.modalService.open(ShipPackageComponent, { size: 'xl' });
      modalRef.componentInstance.oShipData = Object.assign({}, this.oShipData);
      modalRef.componentInstance.saveAnswer.subscribe((confirmation: shipData) => {
        if(confirmation) {
          this.oShipData = confirmation;
          modalRef.close();

          if(this.bSenderData && this.bReceiverData && this.bPackageData) this.loadCourierServices();
        }
      });
    }
  }

  loadCourierServices(){
    this.bLoadService = true;
  }

  resetShipData() {
    this.bLoadService = false;
  }

  selectService(service: courierServiceAvailableData) {

    if(service.courier_service !== '') this.serviceSelected = service;
    if(service.courier_service == '') {

      this.bSenderData = false;
      this.bReceiverData = false;
      this.bPackageData = false;
      this.bLoadService = false;

      this.oShipData = this.vrshippingService.getNewshipData();
      this.oUser = this.vrshippingService.getNewUserProfileData();
      this.aEuRegionCountries = [];
      this.aoUserCourier = [];
      this.serviceSelected = this.vrshippingService.getNewCourierServiceAvailableData();

      this.initAppComponent();

    }
  }

}
