import { Component, OnInit } from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {Router} from "@angular/router";
import {UserEditComponent} from "../user-edit/user-edit.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(public vrshippingService: VrshippingService, private router: Router,
              private modalService: NgbModal,) { }

  ngOnInit(): void {}

  userProfile(){
    const modalRef = this.modalService.open(UserEditComponent, { size: 'xl' });
    modalRef.componentInstance.id_user = this.vrshippingService.userData.id;
    modalRef.componentInstance.edit_type = "profile";
  }

}
