<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Tracking BRT</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">

      <div class="alert alert-danger" *ngIf="bErrorLoadingData">
        <h3>Errore nel caricamento dei dati</h3>
        Il sistema non è riuscito ad identificare la tua spedizione, contatta assistenza per maggiori informazioni.
      </div>

      <section id="dataDetails" *ngIf="!bErrorLoadingData && oTrackingResponse">

        <div class="d-flex flex-wrap mt-2 text-center">
          <div class="flex-fill col-4">
            <div>{{oShip.mit_city}}-{{oShip.mit_state}}</div>
            <div class="small text-muted">{{oShip.mit_agency}}</div>
          </div>
          <div class="flex-fill col-4">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            <div>
              Pacchi: {{oShip.packages.length}}
            </div>
          </div>
          <div class="flex-fill col-4">
            <div>{{oShip.dest_city}}-{{oShip.dest_state}}</div>
            <div class="small text-muted">{{oShip.dest_agency}}</div>
          </div>
        </div>
        <hr />

        <div class="mt-3" *ngFor="let pack of oTrackingResponse; let i = index">
          <h5>Pacco {{oShip.packages[i].number}} - {{oShip.packages[i].tracking}}</h5>

          <div class="alert alert-warning" *ngIf="pack.ttParcelIdResponse.executionMessage.code < 0">
            <h5>Nessuna informazione disponibile sulla spedizione</h5>
          </div>

          <div class="d-flex flex-wrap" *ngIf="pack.ttParcelIdResponse.executionMessage.code >= 0">
            <div *ngFor="let step of pack.ttParcelIdResponse.lista_eventi">
              <div class="border mt-2 mb-2 me-2 bg-primary text-center" style="--bs-bg-opacity: .1;" *ngIf="step.evento.id !== ''">
                <div class="p-3">
                  {{step.evento.data}} {{step.evento.ora}}
                  <div>{{step.evento.descrizione}}</div>
                  <div>{{step.evento.filiale}}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>

