import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {courierData, userProfileData} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-courier-edit',
  templateUrl: './courier-edit.component.html',
  styleUrls: ['./courier-edit.component.scss']
})
export class CourierEditComponent implements OnInit {

  @Input() id_courier: number = 0; // 0 => edit profile
  @Output() saveAnswer = new EventEmitter<boolean>();

  public oCourier: courierData = this.vrshippingService.getNewCourierData();
  public bLoadingData: boolean = false;
  public aAllUserInsert: any = null;
  public bLoadingAllUserInsert: boolean = false;
  public bAllUserDelete: boolean = false;
  public bAllUserDeleteCommand: boolean = false;
  public bLoadingAllUserDelete: boolean = false;

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    if(this.id_courier == 0) this.oCourier = this.vrshippingService.getNewCourierData();
    if(this.id_courier !== 0) {
      this.oCourier.id = this.id_courier;
      this.getCourierProfile();
    }
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  checkUserConsistency(){

    /*if(this.oUser.reference == '') return true;
    if(this.oUser.azienda == '') return true;
    if(this.oUser.email == '') return true;
    if(this.oUser.nazione == '--') return true;
    if(this.oUser.nazione == 'IT' && this.oUser.prov == '--') return true;
    if(this.oUser.nazione == 'US' && this.oUser.usstate == '--') return true;
    if(this.oUser.cap == '') return true;
    if(this.oUser.username == '') return true;*/
    return false;
  }

  getCourierProfile() {

    this.bLoadingData = true;

    this.vrshippingService.getCourierListDB(this.vrshippingService.userData.token, this.oCourier.id).subscribe(get_response => {

        if(get_response.couriers && get_response.couriers.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il profilo' + ': ' + get_response.couriers.error);
        } else {
          this.oCourier = get_response.couriers[0];
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  saveProfile() {
    if(this.oCourier.id !== 0) this.updateCourier();
  }

  updateCourier() {

    this.vrshippingService.postCourierUpdateDB(this.oCourier, this.vrshippingService.userData.token).subscribe(update_response => {

        if(update_response.couriers && update_response.couriers.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + update_response.couriers.error);
        } else {
          this.openDialog('success', 'Profilo modificato con successo!');

          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  insertAllUserCourier() {

    this.bLoadingAllUserInsert = true;

    this.vrshippingService.postUserCourierAllUserInsertDB(this.oCourier, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.users && insert_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile abilitare tutti i clienti: ' + insert_response.insert.error);
        } else {
          this.aAllUserInsert = insert_response.users;
        }

        this.bLoadingAllUserInsert = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingAllUserInsert = false;
      }
    );
  }

  deleteAllUserCourier() {

    this.bLoadingAllUserDelete = true;
    this.bAllUserDeleteCommand = true;

    this.vrshippingService.postUserCourierAllUserDeleteDB(this.oCourier, this.vrshippingService.userData.token).subscribe(delete_response => {

        if(delete_response.users && delete_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile disattivare tutti i clienti: ' + delete_response.insert.error);
          this.bAllUserDelete = false;
        } else {
          this.bAllUserDelete = true;
        }

        this.bLoadingAllUserDelete = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingAllUserDelete = false;
        this.bAllUserDelete = false;
      }
    );
  }

}
