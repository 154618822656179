<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Profilo Utente</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">
  <div class="row mb-2">
    <div class="col-12 col-md-6 mt-3" *ngIf="vrshippingService.userData.type == 'A'">
      <label>Reference (*)</label>
      <input type="text" class="form-control" placeholder="Codice VeronaSped" [(ngModel)]="oUser.reference">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Denominazione Aziendale (*)</label>
      <input type="text" class="form-control" placeholder="Nome azieda" [(ngModel)]="oUser.azienda">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Cognome</label>
      <input type="text" class="form-control" placeholder="Cognome" [(ngModel)]="oUser.cognome">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Nome</label>
      <input type="text" class="form-control" placeholder="Nome" [(ngModel)]="oUser.nome">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Email (*)</label>
      <input type="text" class="form-control" placeholder="e-mail" [(ngModel)]="oUser.email">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Telefono</label>
      <input type="text" class="form-control" placeholder="Contatto telefonico" [(ngModel)]="oUser.tel">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>Nazione (*)</label>
      <select class="form-control" [(ngModel)]="oUser.nazione">
        <option value="--">-- Seleziona nazione --</option>
        <option *ngFor="let nazione of vrshippingService.settingsData.countries" [value]="nazione.iso">{{nazione.printable_name}} ({{nazione.iso}})</option>
      </select>
    </div>
    <div class="col-12 col-md-4 mt-3" *ngIf="oUser.nazione == 'IT'">
      <label>Provincia (*)</label>
      <select class="form-control" [(ngModel)]="oUser.prov">
        <option value="--">-- Seleziona provincia --</option>
        <option *ngFor="let prov of vrshippingService.settingsData.provinces" [value]="prov.sigla">{{prov.prov_desc}} ({{prov.sigla}})</option>
      </select>
    </div>
    <div class="col-12 col-md-4 mt-3" *ngIf="oUser.nazione == 'US'">
      <label>Stato US (*)</label>
      <select class="form-control" [(ngModel)]="oUser.usstate">
        <option value="--">-- Seleziona stato --</option>
        <option *ngFor="let state of vrshippingService.settingsData.us_states" [value]="state.state_code">{{state.state}} ({{state.state_code}})</option>
      </select>
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>CAP (*)</label>
      <input type="text" class="form-control" placeholder="CAP" [(ngModel)]="oUser.cap">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Indirizzo / numero</label>
      <input type="text" class="form-control" placeholder="Indirizzo e numero" [(ngModel)]="oUser.indirizzo">
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label>Città/Comune</label>
      <input type="text" class="form-control" placeholder="Città" [(ngModel)]="oUser.citta">
    </div>
  </div>
  <div class="row mb-4" *ngIf="edit_type == 'gest' || vrshippingService.userData.type == 'A'">
    <div class="col-12 mt-4" >
      <h5>Accesso area riservata</h5>
    </div>
    <div class="col-12 col-md-4 mt-2">
      <label>Nome utente (*)</label>
      <input type="text" class="form-control" placeholder="Nome utente" [(ngModel)]="oUser.username" [disabled]="oUser.id !== 0">
    </div>
    <div class="col-12 col-md-4 mt-2">
      <label>Password</label>
      <input type="text" class="form-control" placeholder="Password" [(ngModel)]="oUser.psw">
    </div>
    <div class="col-12 col-md-4 mt-2">
      <label>Status</label>
      <select class="form-control" [(ngModel)]="oUser.stato">
        <option value="A">Abilitato</option>
        <option value="B">Boccato</option>
      </select>
    </div>
  </div>

  <div class="row mb-4" *ngIf="edit_type == 'gest'">
    <div class="col-12 mt-4" >
      <h5>Corrieri abilitati</h5>
    </div>
    <div class="col-12" *ngIf="oUser.id > 0">
      <div class="d-flex flex-wrap align-items-end">
        <div class="flex-fill me-2 mt-1">
          <label>Corrieri disponibili</label>
          <select class="form-control" [(ngModel)]="oUserCourier.courier_code">
            <option value="">-- Seleziona Corriere --</option>
            <option [value]="courier.code" *ngFor="let courier of aCourierList">{{courier.code}}</option>
          </select>
        </div>
        <div class="flex-fill me-2 mt-1">
          <button class="btn btn-sm btn-primary" (click)="insertUserCourier()">ABILITA CORRIERE</button>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="d-flex flex-wrap">
        <div class="border rounded p-1 text-center me-2 mt-2 col-2" [class]="{'border-success': user_courier.status, 'border-danger':!user_courier.status}" *ngFor="let user_courier of aoUserCourier">
          {{user_courier.courier_code}}
          <div *ngIf="oUser.id !== 0"><button class="btn btn-link" (click)="deleteUserCourier(user_courier)">Elimina</button></div>
        </div>
      </div>
    </div>

  </div>

  <div class="d-flex flex-wrap align-items-end">

    <div class="row">
    <div class="col-12">
      <span class="small text-muted">(*) Campi obbligatori</span>
    </div>
  </div>

  </div>

  <div class="row mt-2 p-2" *ngIf="edit_type == 'gest'">
    <div class="col-12 text-center">
      <button class="btn btn-sm btn-danger" (click)="deleteUser()"><i class="fa fa-trash me-2"></i> ELIMINA PROFILO</button>
      <div class="small text-muted mt-2">
        Elimina in modo definitivo il profilo del cliente.
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" [disabled]="checkUserConsistency()" (click)="saveProfile()">Salva</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
