<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Informazioni sulla spedizione</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

  <div class="row mt-2" *ngIf="oShipData.dest_state !== '--' && !checkEuRegion()">
    <div class="col-12 col-md-3">
      <label>Tipo di spedizione (*)</label>
      <select class="form-control" [(ngModel)]="oShipData.type">
        <option value="DOCUMENTS">Documenti</option>
        <option value="NON_DOCUMENTS">Soggetti a dogana</option>
      </select>
    </div>
    <div class="col-12 col-md-3" *ngIf="oShipData.type == 'NON_DOCUMENTS'">
      <label>Valore economico spedizione (*)</label>
      <input type="text" class="form-control" placeholder="es: 1000,00" [(ngModel)]="oShipData.duty_declared">
    </div>
    <div class="col-12 col-md-3" *ngIf="oShipData.type == 'NON_DOCUMENTS'">
      <label>Dazi a carico (*)</label>
      <select class="form-control" [(ngModel)]="oShipData.duty_type">
        <option value="DAP" selected>DAP - A carico del DESTINATARIO</option>
        <option value="DDP">DDP - A carico del MITTENTE</option>
      </select>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12 col-md-3">
      <label>Data spedizione (*)</label>
      <input id="shipDateBox" type="date" min="2023-02-01" max="2999-12-31" class="form-control" placeholder="gg/mm/aaaa" [(ngModel)]="oShipData.date">
    </div>
    <div class="col-12 col-md-3">
      <label>Ora ritiro (*)</label>
      <select class="form-control" [(ngModel)]="oShipData.hour">
        <option value="09:00:00">09:00</option>
        <option value="09:30:00">09:30</option>
        <option value="10:00:00">10:00</option>
        <option value="10:30:00">10:30</option>
        <option value="11:00:00">11:00</option>
        <option value="11:30:00">11:30</option>
        <option value="12:00:00">12:00</option>
        <option value="12:30:00">12:30</option>
        <option value="13:00:00">13:00</option>
        <option value="13:30:00">13:30</option>
        <option value="14:00:00">14:00</option>
        <option value="14:30:00">14:30</option>
        <option value="15:00:00">15:00</option>
        <option value="15:30:00">15:30</option>
        <option value="16:00:00">16:00</option>
        <option value="16:30:00">16:30</option>
        <option value="17:00:00">17:00</option>
      </select>
    </div>
    <div class="col-12" [class]="{'col-md-6': vrshippingService.userData.type !== 'A', 'col-md-3': vrshippingService.userData.type == 'A'}">
      <label>Descrizione (*)</label>
      <input type="text" class="form-control" placeholder="Descrizione del contenuto" [(ngModel)]="oShipData.general_desc" maxlength="35">
    </div>
    <div class="col-12 col-md-3" *ngIf="vrshippingService.userData.type == 'A'">
      <label>Riferimento (*)</label>
      <input type="text" class="form-control" [(ngModel)]="oShipData.client_reference" maxlength="100">
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12 col-md-6">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="nextDate" [(ngModel)]="oShipData.next_date">
        <label class="form-check-label" for="nextDate">
          Accetto anche date successive
        </label>
      </div>
      <small class="text-muted">Nel caso in cui non fossero disponibili servizi per la data scelta</small>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="insurrenceCheck" [(ngModel)]="oShipData.insurrence">
        <label class="form-check-label" for="insurrenceCheck">
          Voglio assicurare la spedizione
        </label>
      </div>
      <div *ngIf="oShipData.insurrence" class="ms-4">
        <small class="text-muted">Inserire il valore economico da assicurare (*)</small>
        <input class="form-control" type="text" placeholder="es: 1000,00 Euro" [(ngModel)]="oShipData.insurrence_declared">
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="vrshippingService.bPallet">
    <div class="col-12">
      <div class="d-flex flex-wrap mt-3">
        <div class="mt-2 me-2 flex-fill">
          <label>Numero di pallet EPAL</label>
          <input type="number" class="form-control" [(ngModel)]="oShipData.pallet_EPAL">
        </div>
        <div class="mt-2 me-2 flex-fill">
          <label>Numero di pallet EUR</label>
          <input type="number" class="form-control" [(ngModel)]="oShipData.pallet_EUR">
        </div>
        <div class="mt-2 me-2 flex-fill">
          <label>Necessaria sponda idraulica?</label>
          <select class="form-control" [(ngModel)]="oShipData.pallet_tail_lift">
            <option value=" ">NO</option>
            <option value="S">SI</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <h4>Sezione colli</h4>
    </div>
    <div class="col-12">
      <div class="d-flex flex-wrap bg-light p-2 border align-items-center">
        <div class="me-2 mt-2 flex-fill">
          <label>Peso (Kg)</label>
          <input type="number" class="form-control" [(ngModel)]="oPackage.weight_declared">
        </div>
        <div class="me-2 mt-2 flex-fill">
          <label>Lunghezza (cm)</label>
          <input type="number" class="form-control" [(ngModel)]="oPackage.length">
        </div>
        <div class="me-2 mt-2 flex-fill">
          <label>Profondità (cm)</label>
          <input type="number" class="form-control" [(ngModel)]="oPackage.width">
        </div>
        <div class="me-2 mt-2 flex-fill">
          <label>Altezza (cm)</label>
          <input type="number" class="form-control" [(ngModel)]="oPackage.height">
        </div>
        <div class="me-2 mt-2 flex-fill">
          <button class="btn btn-sm btn-primary" (click)="addPackage()"><i class="fa fa-plus"></i> AGGIUNGI</button>
        </div>

      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <div class="alert alert-warning" *ngIf="oShipData.packages.length == 0">
          Attenzione nessun pacco presente per la spedizione
        </div>
        <div class="d-flex flex-wrap">
          <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3" *ngFor="let pack of oShipData.packages">
            <label>Pacco {{pack.number}} - {{pack.weight | number : '1.2-2' }} Kg</label>
            <div class="small">
              <span class="small">Peso volumetrico: {{pack.weight_vol | number : '1.2-2'}} Kg</span> <br />
              <span class="small">L: {{pack.length | number : '1.2-2' }} x P: {{pack.width | number : '1.2-2' }} x A: {{pack.height | number : '1.2-2' }} cm</span>
            </div>

            <div class="mt-2">
              <button class="btn btn-sm btn-danger" (click)="removePackage(pack)"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row mt-4" *ngIf="vrshippingService.userData.type == 'A'">
    <div class="col-12 text-end">
      <select class="form-control" [(ngModel)]="oShipData.drop_off_type">
        <option value="REQUEST_COURIER">Richiesto pickup</option>
        <option value="REGULAR_PICKUP">Pickup già programmato</option>
      </select>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" [disabled]="!checkPackageConsistency()" (click)="savePackageData()">CONFERMA</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
