<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Duplicazione del listino {{courier_code}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

  <div class="row pt-2 pb-2">
    <div class="col-12">
      <div class="d-flex flex-warp justify-items-between">
        <div class="flex-fill text-center">
          <h3>ORIGINE</h3>
          <h4>{{courier_code}}</h4>
        </div>
        <div class="flex-fill text-center">
          <i class="fa fa-arrow-right fa-3x"></i>
        </div>
        <div class="flex-fill text-center">
          <h3>DESTINAZIONE</h3>
          <select class="form-control" [(ngModel)]="oListDuplicatorData.destination_courier">
            <option value="">-- Seleziona corriere --</option>
            <option *ngFor="let courier of aCourierList" [value]="courier.code">{{courier.code}}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-12 mt-5 border-top text-center">
      <button class="btn btn-xl btn-success mt-4" (click)="duplicateListinoShip()" [disabled]="oListDuplicatorData.destination_courier == ''">DUPLICA</button>
    </div>

    <div class="col-12 mt-5 border-top">
      <div class="d-flex flex-wrap">
        <div class="alert alert-success" *ngIf="aDuplicationData.success">
          <h4>Record inseriti: {{aDuplicationData.success.length}}</h4>
        </div>
        <div class="alert alert-danger" *ngIf="aDuplicationData.errors">
          <h4>Errori: {{aDuplicationData.errors.length}}</h4>
          <div class="d-flex flex-wrap">
            <div class="border p-3 mt-2 me-2" *ngFor="let error_row of aDuplicationData.errors">
              <div>Listino: {{error_row.cod_vrsped}}</div>
              <div>Peso min: {{error_row.peso_min}}</div>
              <div>Peso max: {{error_row.peso_max}}</div>
              <div>Zona: {{error_row.zone}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
