<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Profilo Utente</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

  <div class="row mb-2">
    <div class="col-12 col-md-4 mt-3">
      <label>Codice</label>
      <input type="text" class="form-control" placeholder="Codice VeronaSped" disabled [(ngModel)]="oCourier.code">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>Descrizione</label>
      <input type="text" class="form-control" placeholder="Codice VeronaSped" [(ngModel)]="oCourier.description">
    </div>
    <div class="col-12 col-md-4 mt-3">
      <label>DDP Tax</label>
      <input type="number" class="form-control" [(ngModel)]="oCourier.ddp">
    </div>
  </div>

  <div class="row mb-2 mt-4 border-top">
    <div class="col-12 mb-3 mt-3">
      <h4>Assicurazione</h4>
    </div>
    <div class="col-12 col-sm-4">
      <label>Percentuale</label>
      <input type="number" class="form-control" [(ngModel)]="oCourier.insurrence_perc">
    </div>
    <div class="col-12 col-sm-4">
      <label>Valore min IT</label>
      <input type="number" class="form-control" [(ngModel)]="oCourier.insurrence_it">
    </div>
    <div class="col-12 col-sm-4">
      <label>Valore min Estero</label>
      <input type="number" class="form-control" [(ngModel)]="oCourier.insurrence_world">
    </div>
  </div>

  <div class="row mb-2 mt-4 border-top">
    <div class="col-12 mb-3 mt-3">
      <h5>Aree remote</h5>
    </div>
    <div class="col-12 col-sm-4">
      <label>Italia</label>
      <input type="number" class="form-control" [(ngModel)]="oCourier.remote_area_it">
    </div>
    <div class="col-12 col-sm-4">
      <label>Estero</label>
      <input type="number" class="form-control" [(ngModel)]="oCourier.remote_area_world">
    </div>
  </div>

  <div class="row mb-2 mt-4 border-top">
    <div class="col-12 mb-3 mt-3">
      <h4>Abilitazioni clienti di massa</h4>
    </div>
    <div class="col-12 col-md-6">
      <button class="btn btn-primary me-2" (click)="insertAllUserCourier()">ABILITA TUTTI I CLIENTI</button>

      <div class="mt-3">
        <div class="alert alert-info" *ngIf="bLoadingAllUserInsert && !aAllUserInsert">
          <i class="fa fa-spinner fa-spin"></i> Elaborazione in corso...
        </div>
        <div class="alert alert-success" *ngIf="aAllUserInsert">
          <strong>Utenti inseriti: {{aAllUserInsert.success.length}}</strong>
        </div>
        <div class="alert alert-danger" *ngIf="aAllUserInsert">
          Errori: {{aAllUserInsert.errors.length}}
          <div class="d-flex flex-wrap" *ngFor="let user of aAllUserInsert.errors">
            <div class="me-2 mt-2 border">{{user.reference}}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 col-md-6">
      <button class="btn btn-primary me-5" (click)="deleteAllUserCourier()">DISABILITA TUTTI I CLIENTI</button>
      <div class="mt-3" *ngIf="bAllUserDeleteCommand">
        <div class="alert alert-info" *ngIf="bLoadingAllUserDelete">
          <i class="fa fa-spinner fa-spin"></i> Elaborazione in corso...
        </div>
        <div class="alert alert-success" *ngIf="bAllUserDelete">
          <strong>Utenti disabilitati</strong>
        </div>
        <div class="alert alert-danger" *ngIf="!bAllUserDelete && !bLoadingAllUserDelete">
          <strong>Utenti NON disabilitati</strong>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">
  <button class="btn btn-success" [disabled]="checkUserConsistency()" (click)="saveProfile()">SALVA</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
