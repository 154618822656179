import { Component, OnInit } from '@angular/core';
import {addressProfile} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {AddressProfileComponent} from "../../components/address-profile/address-profile.component";

@Component({
  selector: 'app-receivers',
  templateUrl: './receivers.component.html',
  styleUrls: ['./receivers.component.scss']
})
export class ReceiversComponent implements OnInit {

  public bLoadingData: boolean = false;
  public aReceivers: Array<addressProfile> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getReceiverList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getReceiverList () {

    this.bLoadingData = true;

    this.vrshippingService.getAddressProfileListDB(this.vrshippingService.userData.token, 'receiver').subscribe(get_response => {

        if(get_response.profiles && get_response.profiles.error) {
          //Error
          this.openDialog('danger', "Errore nel caricamento dei destinatari: " + get_response.profiles.error);
        } else {

          this.aReceivers = get_response.profiles;
        }
        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', "Errore imprevisto del server: " + error.message);
        this.bLoadingData = false;
      });
  }

  editProfile(id_profile: number) {
    const modalRef = this.modalService.open(AddressProfileComponent, { size: 'xl' });
    modalRef.componentInstance.id_profile = id_profile;
    modalRef.componentInstance.edit_type = 'receiver';
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {

      if(confirmation) {
        this.getReceiverList();
      }
    });
  }

  saveProfile(profile: addressProfile, type: string){
    profile.stato = type;
    this.updateProfile(profile);
  }

  updateProfile(profile: addressProfile) {

    this.vrshippingService.postAddressProfileUpdateDB(profile, 'receiver', this.vrshippingService.userData.token).subscribe(update_response => {

        if(update_response.profiles && update_response.profiles.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + update_response.profiles.error);
        } else {
          this.openDialog('success', 'Profilo modificato con successo!');
          this.getReceiverList();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

}
