import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {listinoData, listinoShipUpgradeData} from "../../interfaces/vrs_interfaces";

@Component({
  selector: 'app-list-ship-upgrade',
  templateUrl: './list-ship-upgrade.component.html',
  styleUrls: ['./list-ship-upgrade.component.scss']
})
export class ListShipUpgradeComponent implements OnInit {

  @Input() courier_code: string = "";
  @Output() saveAnswer = new EventEmitter<boolean>();

  public bLoadingData: boolean = false;
  public oListShipUpgrade: listinoShipUpgradeData = this.vrshippingService.getNewListShipUpgradeData();
  public aList: Array<listinoData> = [];
  public aUpgradeResponseData: any = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.getListinoList();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getListinoList() {

    this.bLoadingData = true;

    this.vrshippingService.getListinoListDB(this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.lists && get_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i listini' + ': ' + get_response.lists.error);
        } else {
          this.aList = get_response.lists;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  upgradeListinoShip(){

    this.bLoadingData = true;
    this.oListShipUpgrade.courier_code = this.courier_code;

    this.vrshippingService.postListinoShipUpgradeDB(this.oListShipUpgrade, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.lists && insert_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile aggiornare il listino: ' + JSON.stringify(insert_response.lists.error));
          this.aUpgradeResponseData = [];
        } else {
          this.aUpgradeResponseData = insert_response.lists;
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
        this.aUpgradeResponseData = [];
      }
    );

  }

}
