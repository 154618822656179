<div class="row pb-4 mt-4">
  <div class="col-12">
    <div class="d-flex flex-wrap justify-content-center">
      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" routerLink="/shipping" (click)="vrshippingService.bPallet = false;">
        <div class="icon-wrapper text-center">
          <img class="icon m-2" src="assets/img/nuovaspedizione.svg" alt="nuova spedizione">
        </div>
        <span> NUOVA SPEDIZIONE</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" routerLink="/pallet-service" (click)="vrshippingService.bPallet = true;">
        <div class="icon-wrapper text-center">
          <img class="icon m-2" src="assets/img/nuovaspedizione.svg" alt="Servizio Pallet">
        </div>
        <span> SERVIZIO PALLET NAZIONALE</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" routerLink="/senders">
        <div class="icon-wrapper text-center">
          <img class="icon m-2" src="assets/img/Mittenti.svg" alt="mittenti">
        </div>
        <span> MITTENTI</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" routerLink="/receivers">
        <div class="icon-wrapper text-center">
          <img class="icon m-2" src="assets/img/Destinatari.svg" alt="destinatari">
        </div>
        <span> DESTINATARI</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" routerLink="/archive">
        <div class="icon-wrapper text-center">
          <img class="icon m-2" src="assets/img/Archivio.svg" alt="archivio">
        </div>
        <span> ARCHIVIO SPEDIZIONI</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" *ngIf="vrshippingService.userData.type == 'A'" routerLink="/users">
        <div class="icon-wrapper text-center">
          <i class="fa fa-users fa-3x"></i>
        </div>
        <span> UTENTI</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" *ngIf="vrshippingService.userData.type == 'A'" routerLink="/list">
        <div class="icon-wrapper text-center">
          <i class="fa fa-euro-sign fa-3x"></i>
        </div>
        <span> LISTINI</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" *ngIf="vrshippingService.userData.type == 'A'" routerLink="/couriers">
        <div class="icon-wrapper text-center">
          <i class="fa fa-truck fa-3x"></i>
        </div>
        <span> CORRIERI</span>
      </div>

      <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3 col-4" (click)="userProfile()">
        <div class="icon-wrapper text-center">
          <i class="fa fa-user fa-3x"></i>
        </div>
        <span> PROFILO</span>
      </div>

    </div>
  </div>
</div>
