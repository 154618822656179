import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {courierUserData, userData, userProfileData} from "../../interfaces/vrs_interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  @Input() id_user: number = 0; // 0 => edit profile
  @Input() edit_type: string = ''; // profile | gest
  @Output() saveAnswer = new EventEmitter<boolean>();

  public oUser: userProfileData = this.vrshippingService.getNewUserProfileData();
  public oUserCourier: courierUserData = this.vrshippingService.getNewUserCourierData(0, '');
  public aoUserCourier: Array<courierUserData> = [];
  public aCourierList: any = this.vrshippingService.settingsData.couriers;
  public bLoadingData: boolean = false;

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal, public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    if(this.id_user == 0) this.oUser = this.vrshippingService.getNewUserProfileData();
    if(this.id_user == 0) this.setAllCourier();

    if(this.id_user !== 0) {
      this.oUser.id = this.id_user;
      this.getUserProfile();
    }
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  setAllCourier() {
    this.vrshippingService.settingsData.couriers.forEach((courier: any) => {
      let oUserCourier: courierUserData = this.vrshippingService.getNewUserCourierData(0, '');
      oUserCourier.courier_code = courier.code;
      oUserCourier.status = true;
      this.aoUserCourier.push(oUserCourier);
    });
  }

  checkUserConsistency(){

    if(this.oUser.reference == '') return true;
    if(this.oUser.azienda == '') return true;
    if(this.oUser.email == '') return true;
    if(this.oUser.nazione == '--') return true;
    if(this.oUser.nazione == 'IT' && this.oUser.prov == '--') return true;
    if(this.oUser.nazione == 'US' && this.oUser.usstate == '--') return true;
    if(this.oUser.cap == '') return true;
    if(this.oUser.username == '') return true;
    return false;
  }

  getUserProfile() {

    this.bLoadingData = true;

    this.vrshippingService.getUsersListDB(this.vrshippingService.userData.token, this.oUser.id).subscribe(get_response => {

        if(get_response.users && get_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il profilo utente' + ': ' + get_response.users.error);
        } else {
          this.oUser = get_response.users[0];
          if(this.edit_type == 'gest') this.getUserCourierList();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getUserCourierList(){

    this.vrshippingService.getUserCourierListDB(this.oUser.id, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.couriers && get_response.couriers.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i corrieri abilitati' + ': ' + get_response.couriers.error);
        } else {
          this.aoUserCourier = get_response.couriers;

          this.aoUserCourier.map(uc => {
            this.aCourierList = this.aCourierList.filter((x: any) => x.code !== uc.courier_code);
          });
        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      });

  }

  saveProfile() {
    if(this.oUser.id == 0) this.insertUser();
    if(this.oUser.id !== 0) this.updateUser();
  }

  insertUser() {

    this.bLoadingData = true;
    this.oUser.courier_list = this.aoUserCourier;

    this.vrshippingService.postUserInsertDB(this.oUser, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.users && insert_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + JSON.stringify(insert_response.users.error));
        } else {
          this.openDialog('success', 'Profilo inserito con successo!');
          this.oUser.id = insert_response.users.id;
          this.getUserProfile();
          if(this.edit_type == 'gest') this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  updateUser() {

    this.vrshippingService.postUserUpdateDB(this.oUser, this.vrshippingService.userData.token).subscribe(update_response => {

        if(update_response.users && update_response.users.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + update_response.users.error);
        } else {
          this.openDialog('success', 'Profilo modificato con successo!');

          if(this.edit_type == 'profile') {
            this.vrshippingService.userData.azienda = this.oUser.azienda;
            this.vrshippingService.userData.cognome = this.oUser.cognome;
            this.vrshippingService.userData.nome = this.oUser.nome;
            this.vrshippingService.userData.email = this.oUser.email;
            this.vrshippingService.setUserLoginData(this.vrshippingService.userData);
          }

          if(this.edit_type == 'gest') this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );
  }

  deleteUser() {
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = 'confirm';
    modalRef.componentInstance.message = "Confermi di voler eliminate questo profilo utente: " + this.oUser.reference + "?";
    modalRef.componentInstance.questionAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {

        this.vrshippingService.postUserDeleteDB(this.oUser, this.vrshippingService.userData.token).subscribe(delete_response => {

            if(delete_response.users && delete_response.users.error) {
              //Error
              this.openDialog('danger', 'Attenzione, impossibile eliminare il profilo: ' + delete_response.users.error);
            } else {
              this.openDialog('success', 'Profilo eliminato con successo!');
              this.saveAnswer.emit(true);
              this.activeModal.close('');
            }

            this.bLoadingData = false;

          },
          error => {
            // API call error
            console.log(error);
            this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
            this.bLoadingData = false;
          }
        );

      }
    });
  }

  insertUserCourier(){

    this.bLoadingData = true;

    this.oUserCourier.id_user = this.oUser.id;

    this.vrshippingService.postUserCourierInsertDB(this.oUserCourier, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.couriers && insert_response.couriers.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il dato: ' + JSON.stringify(insert_response.couriers.error));
        } else {
          this.oUserCourier = this.vrshippingService.getNewUserCourierData(this.oUser.id, '');
          this.openDialog('success', 'Corriere aggiunto con successo!');
          this.getUserCourierList();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  updateUserCourier(oUserCourier: courierUserData){

    this.bLoadingData = true;

    this.vrshippingService.postUserCourierUpdateDB(oUserCourier, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.couriers && insert_response.couriers.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il dato: ' + JSON.stringify(insert_response.couriers.error));
        } else {
          this.openDialog('success', 'Corrieri modificato con successo!');

          this.getUserCourierList();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  deleteUserCourier(oUserCourier: courierUserData){

    this.bLoadingData = true;

    this.vrshippingService.postUserCourierDeleteDB(oUserCourier, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.couriers && insert_response.couriers.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il dato: ' + JSON.stringify(insert_response.couriers.error));
        } else {
          this.openDialog('success', 'Corrieri eliminato con successo!');
          this.aCourierList = this.vrshippingService.settingsData.couriers;
          this.getUserCourierList();

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

}
