<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row" *ngIf="!bServiceSelected">
  <div class="col-12 mt-2 mb-2" *ngIf="bLoadingData">
    <span><i class="fa fa-spinner fa-spin"></i> Caricamento servizi DHL in corso...</span>
  </div>
  <div class="col-12 mt-2">
    <div class="alert alert-danger" *ngIf="oDhlRateNotification?.code && oDhlRateNotification.code !== '0'">
      <h5>Errore nella chiamata DHL</h5>
      {{ oDhlRateNotification.Message }}
    </div>
    <div class="alert alert-danger" *ngIf="oDhlRateNotification?.length > 0">
      <h5>Errore nella chiamata DHL</h5>
      <div class="mt-2" *ngFor="let error of oDhlRateNotification">
        <span *ngIf="error.code !== '999'">{{error.code}} -> {{error.Message}}</span>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-center">
      <div class="mt-3 me-3 p-2 border bg-light col-5" *ngFor="let service of aAvailableService">
        <div class="row">
          <div class="col-3">
            <img src="assets/img/dhl/logo.png" alt="" class="img-fluid" />
           </div>
          <div class="col-9 text-center">
            <div class="border border-success text-success rounded p-1 fw-bolder" *ngIf="service.list_price > 0">
              {{ service.list_price + service.fuel_cost + service.insurrence_cost + service.remote_zone_cost + service.ddp_cost | number:'1.2-2' }} €
              <div class="small text-muted">
                {{service.courier_service_desc}}
              </div>
            </div>
            <div class="bg-light-orange border rounded p-1 fw-bolder" *ngIf="service.list_price == 0">
              n.a.
              <div class="small text-muted">
                {{service.courier_service_desc}}
              </div>
            </div>
          </div>
          <div class="col-6 mt-3">
            <table class="table">
              <thead>
                <tr>
                  <th class="small text-muted">Voce</th>
                  <th class="small text-muted text-end">Prezzo</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>Spedizione</td>
                <td class="text-end">
                  <span *ngIf="service.list_price > 0">{{service.list_price | number: '1.2-2'}}</span>
                  <span *ngIf="service.list_price == 0">n.a.</span>
                </td>
              </tr>
              <tr *ngIf="service.fuel_cost > 0">
                <td>Suppl. Fuel</td>
                <td class="text-end">{{service.fuel_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.insurrence_cost > 0">
                <td>Assicurazione</td>
                <td class="text-end">{{service.insurrence_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.remote_zone_cost > 0">
                <td>Zona remota</td>
                <td class="text-end">{{service.remote_zone_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.ddp_cost > 0">
                <td>Disbrigo pratiche DDP</td>
                <td class="text-end">{{service.ddp_cost | number: '1.2-2'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 mt-3 text-center">
            <div class="mt-2 small">
              <span class="text-muted">Ritiro</span>
              <div>{{service.pickup_date | date: 'dd/MM/yyyy HH:mm'}}</div>
            </div>
            <div class="mt-2 small">
              <span class="text-muted">Consegna</span>
              <div>{{service.pickup_delivery | date: 'dd/MM/yyyy'}} entro le {{service.pickup_delivery | date: 'HH:mm'}}</div>
            </div>
            <div class="mt-3">
              <button class="btn btn-sm btn-primary" *ngIf="service.list_price > 0" (click)="selectService(service)">SELEZIONA SERVIZIO</button>
              <div class="text-danger" *ngIf="service.list_price == 0">
                Chiama per un preventivo
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="bPaperless">
  <div class="col-12 mt-2 mb-2">
    <h4>Servizio Paperless Trade (Opzionale)</h4>
    <p>
      DHL Paperless Trade è un servizio gratuito che consente al mittente di fornire i documenti necessari all’esportazione (come le fatture commerciali o proforma) in formato digitale, caricandoli durante la creazione delle spedizioni.
      Questo servizio elimina la necessità di stampare e allegare manualmente i documenti, facendoti risparmiare tempo e denaro e salvaguardando l'ambiente.
    </p>
  </div>
  <div class="col-12 col-md-4 mt-2">
    <label>Tipo documento</label>
    <select class="form-control mt-2" [(ngModel)]="oPaperlessDocument.type">
      <option value="--">-- Seleziona il tipo di documento --</option>
      <option value="INV">Invoice</option>
      <option value="PNV">Proforma</option>
      <option value="COO">Certificate of Origin</option>
      <option value="NAF">Nafta Certificate of Origin</option>
      <option value="CIN">Commercial Invoice</option>
      <option value="DCL">Custom Declaration</option>
      <option value="AWB">Air Waybill and Waybill Document</option>
    </select>
  </div>
  <div class="col-12 col-md-4 mt-2">
    <label>Seleziona il documento <i class="fa fa-info-circle" [ngbTooltip]="tipFormat"></i></label>
    <ng-template #tipFormat>
      PDF, PNG, TIFF, GIF, JPEG
    </ng-template>
    <input type="file" class="form-control mt-2" accept=".pdf,.png, .tiff, .gif, .jpg, .jpeg" (change)="picked($event)">
  </div>
  <div class="col-12 col-md-4 mt-2 text-center">
    <button class="btn btn-primary mt-3" (click)="addPaperlessFile()">AGGIUNGI FILE</button>
  </div>

  <div class="col-12 mt-3">
    <table class="table table-responsive table-hover table-striped table-bordered">
      <thead>
        <tr >
          <th>Tipo</th>
          <th>Nome file</th>
          <th>Estensione</th>
          <th>#</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let doc of oShipData.documents">
          <td>{{doc.type}}</td>
          <td>{{doc.file_name}}</td>
          <td>{{doc.format}}</td>
          <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removePaperlessFile(doc)"><i class="fa fa-trash"></i></button></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-12 mt-4 mb-2 text-center">
    <button class="btn btn-success" (click)="insertShipRequest()">CONFERMA SPEDIZIONE</button>
  </div>

</div>

<div class="row" *ngIf="bServiceSelected && !bPaperless && !bLoadingData">
  <div class="col-12">
    <div class="alert alert-danger" *ngIf="oDhlShipNotification?.code && oDhlShipNotification.code !== '0'">
      <h5>Errore nella chiamata DHL</h5>
      {{ oDhlShipNotification.Message }}
    </div>
    <div class="alert alert-danger" *ngIf="oDhlShipNotification?.length > 0">
      <h5>Errore nella chiamata DHL</h5>
      <div class="mt-2" *ngFor="let error of oDhlShipNotification">
        <span *ngIf="error.code !== '999'">{{error.code}} -> {{error.Message}}</span>
      </div>
    </div>

    <div class="alert alert-success" *ngIf="oShipData.pdf_base64">
      <div class="mt-2">
        <strong>Ship ID</strong> {{oShipData.ship_id}}
      </div>
      <div class="mt-2">
        <strong>Numero conferma spedizione</strong> {{oShipData.dispatch_number}}
      </div>
      <div class="fw-bold mt-4" *ngFor="let pdf of oShipData.packages_courier_response">
        <strong>Traking pacco {{pdf.number}}</strong> {{pdf.tracking_number}}
      </div>
      <div class="text-center mt-3">
        <button class="btn btn-success" (click)="onClickDownloadPdf()">SCARICA DOCUMENTI PDF</button>
      </div>
    </div>

  </div>

  <div class="col-12 mt-3 text-center">
    <button class="btn btn-sm btn-primary" (click)="newShipping()">NUOVA SPEDIZIONE</button>
  </div>

</div>
