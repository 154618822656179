import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomepageComponent} from "./pages/homepage/homepage.component";
import {Error404Component} from "./pages/error404/error404.component";
import {CourierPageComponent} from "./pages/courier-page/courier-page.component";
import {UsersComponent} from "./pages/users/users.component";
import {SendersComponent} from "./pages/senders/senders.component";
import {ReceiversComponent} from "./pages/receivers/receivers.component";
import {ListPageComponent} from "./pages/list-page/list-page.component";
import {ShippingDataComponent} from "./pages/shipping-data/shipping-data.component";
import {ShippingArchiveComponent} from "./pages/shipping-archive/shipping-archive.component";

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'home', component: HomepageComponent },
  { path: 'couriers', component: CourierPageComponent },
  { path: 'users', component: UsersComponent },
  { path: 'senders', component: SendersComponent },
  { path: 'receivers', component: ReceiversComponent },
  { path: 'list', component: ListPageComponent },
  { path: 'shipping', component: ShippingDataComponent },
  { path: 'pallet-service', component: ShippingDataComponent },
  { path: 'archive', component: ShippingArchiveComponent },
  { path: '**', component:  Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    useHash: true
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
