<app-loader *ngIf="bLoadingData"></app-loader>

<div class="modal-header">
  <h4>Tracking FEDEX</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>

<div class="modal-body">

  <div class="row">
    <div class="col-12">

      <div class="alert alert-danger" *ngIf="bErrorLoadingData">
        <h3>Errore nel caricamento dei dati</h3>
        Il sistema non è riuscito ad identificare la tua spedizione, contatta assistenza per maggiori informazioni.
      </div>

      <section id="dataDetails" *ngIf="!bErrorLoadingData && oTrackingResponse">
        <div *ngIf="oTrackingResponse.errors">
          <div class="alert alert-danger" *ngFor="let error of oTrackingResponse.errors">
            <h5>{{error.code}}</h5>
            {{error.message}}
          </div>
        </div>

        <div *ngIf="oTrackingResponse.output">

          <div class="alert alert-danger mt-2 mb-2" *ngFor="let error of aErrors">
            <h5>{{error.code}}</h5>
            {{error.message}}
          </div>

          <div class="d-flex flex-wrap">
            <div class="border mt-2 mb-2 me-2 bg-primary text-center" style="--bs-bg-opacity: .1;" *ngFor="let step of aSteps">
              <div class="p-3">
                {{step.date| date: 'dd/MM/yyyy HH:mm:ss'}}
                <div>{{step.message}}</div>
              </div>
            </div>
          </div>
        </div>

      </section>

    </div>
  </div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close('')">CHIUDI</button>
</div>
