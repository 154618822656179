import { Component, OnInit } from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {courierData} from "../../interfaces/vrs_interfaces";
import {AlertDialogComponent} from "../../components/alert-dialog/alert-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UserEditComponent} from "../../components/user-edit/user-edit.component";
import {CourierEditComponent} from "../../components/courier-edit/courier-edit.component";
import {CourierServiceComponent} from "../../components/courier-service/courier-service.component";

@Component({
  selector: 'app-courier-page',
  templateUrl: './courier-page.component.html',
  styleUrls: ['./courier-page.component.scss']
})
export class CourierPageComponent implements OnInit {

  public bLoadingData: boolean = false;

  public aCourierList: Array<courierData> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getCourierList ();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'sm' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getCourierList () {

    this.bLoadingData = true;

    this.vrshippingService.getCourierListDB(this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.couriers && get_response.couriers.error) {
          //Error
          this.openDialog('danger', "Errore nel caricamento dei corrieri: " + get_response.couriers.error);
        } else {

          this.aCourierList = get_response.couriers;

        }

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', "Errore imprevisto del server: " + error.message);
      });
  }

  editCourier(id_courier: number) {
    const modalRef = this.modalService.open(CourierEditComponent, { size: 'xl' });
    modalRef.componentInstance.id_courier = id_courier;
    modalRef.componentInstance.saveAnswer.subscribe((confirmation: boolean) => {
      if(confirmation) {
        this.getCourierList();
      }
    });
  }

  editCourierService(code_courier: string) {
    const modalRef = this.modalService.open(CourierServiceComponent, { size: 'xl' });
    modalRef.componentInstance.code_courier = code_courier;
  }

}
