import { Component, OnInit } from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public vrshippingService: VrshippingService, private router: Router) { }

  ngOnInit(): void {}

  logoutUser() {
    this.vrshippingService.bLoggedIn = false;
    this.vrshippingService.getUserLogoutData();
    this.router.navigate(['home']);
  }

}
