<app-loader *ngIf="bLoadingData"></app-loader>

<div class="row" *ngIf="!bServiceSelected">
  <div class="col-12 mt-2 mb-2" *ngIf="bLoadingData">
    <span><i class="fa fa-spinner fa-spin"></i> Caricamento servizi FEDEX in corso...</span>
  </div>

  <div class="col-12">
    <div class="alert alert-danger" *ngIf="oFedexRateNotification?.Code && oFedexRateNotification.Code !== '668'">
      <h5>Errore nella chiamata FEDEX</h5>
      {{ oFedexRateNotification.Message }}
    </div>
    <div class="alert alert-danger" *ngIf="oFedexRateNotification?.length > 0">
      <h5>Errore nella chiamata FEDEX</h5>
      <div class="mt-2" *ngFor="let error of oFedexRateNotification">
        <div *ngIf="error.Severity !== 'NOTE' || error.Severity !== 'WARNING'">
          <span>{{error.Code}} -> {{error.Message}}</span>
        </div>

      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-center">
      <div class="mt-3 me-3 p-2 border bg-light col-5" *ngFor="let service of aAvailableService">
        <div class="row">
          <div class="col-3">
            <img src="assets/img/fedex/logo.png" alt="" class="img-fluid" />
          </div>
          <div class="col-9 text-center">
            <div class="border border-success text-success rounded p-1 fw-bolder" *ngIf="service.list_price > 0">
              {{ service.list_price + service.fuel_cost + service.insurrence_cost + service.remote_zone_cost + service.ddp_cost | number:'1.2-2' }} €
              <div class="small text-muted">
                {{service.courier_service_desc}}
              </div>
            </div>
            <div class="bg-light-orange border rounded p-1 fw-bolder" *ngIf="service.list_price == 0">
              n.a.
              <div class="small text-muted">
                {{service.courier_service_desc}}
              </div>
            </div>
          </div>
          <div class="col-6 mt-3">
            <table class="table">
              <thead>
              <tr>
                <th>Dettaglio</th>
                <th>Prezzo</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Spedizione</td>
                <td class="text-end">
                  <span *ngIf="service.list_price > 0">{{service.list_price | number: '1.2-2'}}</span>
                  <span *ngIf="service.list_price == 0">n.a.</span>
                </td>
              </tr>
              <tr *ngIf="service.fuel_cost > 0">
                <td>Suppl. Fuel</td>
                <td class="text-end">{{service.fuel_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.insurrence_cost > 0">
                <td>Assicurazione</td>
                <td class="text-end">{{service.insurrence_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.remote_zone_cost > 0">
                <td>Zona remota</td>
                <td class="text-end">{{service.remote_zone_cost | number: '1.2-2'}}</td>
              </tr>
              <tr *ngIf="service.ddp_cost > 0">
                <td>Disbrigo pratiche DDP</td>
                <td class="text-end">{{service.ddp_cost | number: '1.2-2'}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-6 mt-3 text-center">
            <div class="mt-2 small">
              <span class="text-muted">Ritiro</span>
              <div><span class="badge bg-success">Organizzato da Verona Sped</span></div>
            </div>
            <div class="mt-2 small">
              <span class="text-muted">Consegna</span>
              <div>Seguiranno indicazioni</div>
            </div>
            <div class="mt-3">
              <button class="btn btn-sm btn-primary" *ngIf="service.list_price > 0" (click)="selectService(service)">SELEZIONA SERVIZIO</button>
              <div class="text-danger" *ngIf="service.list_price == 0">
                Chiama per un preventivo
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="row" *ngIf="bServiceSelected && !bLoadingData">

  <div class="col-12">
    <div class="alert alert-info mb-2">
      <i class="fa fa-info-circle"></i>
      Attenzione il ritiro da parte di Fedex sarà organizzato direttamente da Verona Sped.<br />
      Seguiranno indicazioni per data e ora del pickup.
    </div>
  </div>

  <div class="col-12">

    <div class="alert alert-danger" *ngFor="let error of oFedexShipNotification">
      <div class="mt-2">
        <h5>Errore nella chiamata Fedex</h5>
        {{ error.code }} - {{error.message}}
      </div>
    </div>

    <div class="mb-2" *ngFor="let label of oFedexShipResponse">

      <div class="alert alert-success" >
        <div class="mt-3">
          <div class="mt-2">
            <div><strong>Master ID</strong> {{label.ship_id}}</div>
          </div>
          <div class="mt-2">
            <div><strong>Traking</strong> {{label.tracking}}</div>
          </div>
          <div class="text-center mt-3">
            <button class="btn btn-success" (click)="downloadPdf(label.stream, 'fedex_' + label.tracking + '.pdf')">SCARICA ETICHETTA PDF</button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="col-12 mt-3 text-center">
    <button class="btn btn-sm btn-primary" (click)="newShipping()">NUOVA SPEDIZIONE</button>
  </div>

</div>
