import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {addressProfile, userProfileData} from "../../interfaces/vrs_interfaces";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-address-profile',
  templateUrl: './address-profile.component.html',
  styleUrls: ['./address-profile.component.scss']
})
export class AddressProfileComponent implements OnInit {

  @Input() id_profile: number = 0; // 0 => new profile
  @Input() edit_type: string = ''; // sender | receiver
  @Output() saveAnswer = new EventEmitter<boolean>();

  public oProfile: addressProfile = this.vrshippingService.getNewAddressProfile();
  public bLoadingData: boolean = false;

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal, public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    if(this.id_profile == 0) this.oProfile = this.vrshippingService.getNewAddressProfile();
    if(this.id_profile !== 0) {
      this.oProfile.id = this.id_profile;
      this.getProfileData();
    }
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getProfileData() {

    this.bLoadingData = true;

    this.vrshippingService.getAddressProfileListDB(this.vrshippingService.userData.token, this.edit_type, this.oProfile.id).subscribe(get_response => {

      if(get_response.profiles && get_response.profiles.error) {
        //Error
        this.openDialog('danger', 'Attenzione, impossibile caricare il profilo' + ': ' + get_response.profiles.error);
      } else {
        this.oProfile = get_response.profiles[0];
      }

      this.bLoadingData = false;

    },
    error => {
      // API call error
      console.log(error);
      this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
      this.bLoadingData = false;
    });

  }

  checkProfileConsistency() {

    if(this.oProfile.rif_cognome == '') return true;
    if(this.oProfile.rif_email == '') return true;
    if(this.oProfile.tel == '') return true;
    if(this.oProfile.nazione == '--') return true;
    if(this.oProfile.prov == '--' || this.oProfile.prov == '') return true;
    if(this.oProfile.citta == '') return true;
    if(this.oProfile.cap == '') return true;
    if(this.oProfile.indirizzo == '') return true;
    return false;

  }

  saveProfile(){
    if(this.oProfile.id == 0) this.insertProfile();
    if(this.oProfile.id !== 0) this.updateProfile();
  }

  insertProfile() {

    this.bLoadingData = true;

    this.vrshippingService.postAddressProfileInsertDB(this.oProfile, this.edit_type, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.profiles && insert_response.profiles.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + JSON.stringify(insert_response.profiles.error));
        } else {
          this.openDialog('success', 'Profilo inserito con successo!');
          this.oProfile.id = insert_response.profiles.id;
          this.getProfileData();
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

  updateProfile() {

    this.bLoadingData = true;

    this.vrshippingService.postAddressProfileUpdateDB(this.oProfile, this.edit_type, this.vrshippingService.userData.token).subscribe(update_response => {

        if(update_response.profiles && update_response.profiles.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il profilo: ' + JSON.stringify(update_response.profiles.error));
        } else {
          this.openDialog('success', 'Profilo modificato con successo!');
          this.getProfileData();
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

}
