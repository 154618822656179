<app-loader *ngIf="bLoadingData"></app-loader>

<div class="container pt-4 pb-4 bg-white">

  <div class="row">
    <div class="col-12">
      <h3>Gestione listini di vendita</h3>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <div class="d-flex flex-wrap align-items-center">
        <div class="box_btn_home_user bg-light-orange border-0 me-3 mt-3" [class]="{'selected': courier.code == oFilter.courier_selected}" *ngFor="let courier of aCourierList" (click)="selectCourierFilter(courier.code)">
          <h3>{{courier.code}}</h3>

          <div class="small" *ngIf="courier.list_update && courier.list_update.length > 0">
            <span class="small">
              Ultimo Agg. {{courier.list_update[0].list_code}} {{courier.list_update[0].date_ins | date: 'dd/MM/YYYY HH:mm:ss'}}
              <span *ngIf="courier.list_update[0].operator == 'plus'">+</span>
              <span *ngIf="courier.list_update[0].operator !== 'plus'">-</span>
              {{courier.list_update[0].perc | number : '1.2-2'}}%
            </span>
          </div>

          <div class="d-flex flex-wrap justify-items-end" *ngIf="courier.code == oFilter.courier_selected && (aListShip && aListShip.data.length > 0)">
            <div class="mt-2 me-2">
              <button class="btn btn-sm btn-primary" (click)="openDuplicator(courier.code)"><i class="fa fa-clone"></i> DUPLICA</button>
            </div>
            <div class="mt-2 me-3">
              <button class="btn btn-sm btn-primary" (click)="openUpgrade(courier.code)"><i class="fa fa-pen"></i> AGGIORNA PREZZI</button>
              <!-- Aggiornare anche tabella listino_ship_update -->
            </div>
            <div class="mt-2 me-2">
              <button class="btn btn-sm btn-primary" (click)="confirmDeleteAllRecord(courier.code)"><i class="fa fa-trash"></i> ELIMINA</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- INSERT/FILTER FORM -->
  <div class="row" *ngIf="oFilter.courier_selected !== '' && aListShip.data.length > 0">
    <div class="col-12">
      <div class="d-flex flex-wrap align-items-end border bg-light mt-3 mb-3 p-3">
        <div class="flex-fill me-2 mt-2">
          <label>Listino</label>
          <select class="form-control mt-2" [(ngModel)]="oFilter.list_code" (change)="listCodeChange()">
            <option value="">-- Seleziona listino --</option>
            <option [value]="list.codice" *ngFor="let list of aList">{{list.codice}} {{list.description}}</option>
          </select>
        </div>
        <div class="flex-fill me-2 mt-2">
          <label>Zona</label>
          <select class="form-control mt-2" [(ngModel)]="oFilter.zone_code" [disabled]="oFilter.list_code == ''">
            <option value="">-- Seleziona zona --</option>
            <option [value]="zone.id" *ngFor="let zone of aZoneList">{{zone.description}}</option>
          </select>
        </div>
        <div class="flex-fill me-2 mt-2" *ngIf="oFilter.zone_code !== ''">
          <label>Peso min</label>
          <input type="number" class="form-control" [(ngModel)]="oListinoShip.peso_min">
        </div>
        <div class="flex-fill me-2 mt-2" *ngIf="oFilter.zone_code !== ''">
          <label>Peso max</label>
          <input type="number" class="form-control" [(ngModel)]="oListinoShip.peso_max">
        </div>
        <div class="flex-fill me-2 mt-2" *ngIf="oFilter.zone_code !== ''">
          <label>Prezzo</label>
          <input type="number" class="form-control" [(ngModel)]="oListinoShip.price">
        </div>
        <div class="flex-fill me-2 mt-2 mb-1" *ngIf="oFilter.zone_code !== ''">
          <button class="btn btn-sm btn-success" (click)="modifyListinoShip('insert', oListinoShip)"><i class="fa fa-plus"></i> Inserisci</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5" *ngIf="oFilter.courier_selected !== ''">
    <div class="col-12" *ngIf="aListShip && aListShip.data.length == 0">
      <div class="alert alert-warning">
        <h4>Attenzione nessun lisitno inserito per il corriere {{oFilter.courier_selected}}</h4>
      </div>
    </div>
    <div class="col-12" *ngIf="aListShip && aListShip.data.length > 0">

      <div class="d-flex justify-content-between">
        <div>
          <h5>Listino {{oFilter.courier_selected}} {{oFilter.list_code}}</h5>
          <div class="small text-muted" *ngIf="oLastUpdate.list_code">
            Ultimo Agg. {{oLastUpdate.list_code}} {{oLastUpdate.date_ins | date: 'dd/MM/yyyy HH:mm:ss'}}
            <span *ngIf="oLastUpdate.operator !== 'plus'">-</span>
            <span *ngIf="oLastUpdate.operator == 'plus'">+</span>
            {{oLastUpdate.perc}}%
          </div>

        </div>
        <div><span class="badge rounded-pill text-bg-primary">Nr. record {{oFilter.record_number}}</span></div>
      </div>

      <table class="mt-3 table table-responsive table-hover table-bordered">
        <thead>
          <tr>
            <th>Listino</th>
            <th>Zona</th>
            <th>Peso min</th>
            <th>Peso max</th>
            <th>Prezzo</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list_ship of filterListShip(aListShip.data)">
            <td>{{list_ship.cod_vrsped}}</td>
            <td>{{list_ship.zone}}</td>
            <td>
              <input type="number" class="form-control" [(ngModel)]="list_ship.peso_min">
            </td>
            <td>
              <input type="number" class="form-control" [(ngModel)]="list_ship.peso_max">
            </td>
            <td>
              <input type="number" class="form-control" [(ngModel)]="list_ship.price">
            </td>
            <td class="text-center">
              <button class="btn btn-sm btn-success me-2 mb-2" (click)="modifyListinoShip('update', list_ship)"><i class="fa fa-save"></i></button>
              <button class="btn btn-sm btn-danger me-2 mb-2" (click)="modifyListinoShip('delete', list_ship)"><i class="fa fa-trash"></i></button>
              <div class="text-muted small">
                <span class="small" *ngIf="list_ship.date_upd">Agg. {{list_ship.date_upd | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
