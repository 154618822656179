<div class="container pt-4 pb-4 bg-white">
  <div class="row">
    <div class="col-12">
      <h3>Gestione corrieri piattaforma</h3>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-responsive table-striped table-hover table-bordered">
        <thead>
          <tr>
            <th>Codice</th>
            <th>Descrizione</th>
            <!--<th>% Assicurazione</th>
            <th>Assicurazione IT</th>
            <th>Assicurazione ALL</th>
            <th>Area Remota IT</th>
            <th>Area Remota ALL</th>-->
            <!--<th>Status</th>-->
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let courier of aCourierList">
            <td>{{courier.code}}</td>
            <td>{{courier.description}}</td>
            <!--<td>{{courier.insurrence_perc | number : '1.2-2'}}</td>
            <td>{{courier.insurrence_it | number : '1.2-2'}}</td>
            <td>{{courier.insurrence_world | number : '1.2-2'}}</td>
            <td>{{courier.remote_area_it | number : '1.2-2'}}</td>
            <td>{{courier.remote_area_world | number : '1.2-2'}}</td>-->
            <!--<td>
              <span class="text-success" *ngIf="courier.status"><i class="fa fa-check-circle"></i></span>
              <span class="text-danger" *ngIf="!courier.status"><i class="fa fa-check-circle"></i></span>
            </td>-->
            <td>
              <button class="btn btn-sm btn-info mt-1" (click)="editCourier(courier.id)"><i class="fa fa-pencil-alt"></i> MODIFICA</button>
              <button class="btn btn-sm btn-primary ms-2 mt-1" (click)="editCourierService(courier.code)"><i class="fa fa-list"></i> SERVIZI</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
