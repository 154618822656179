import {Component, Input, OnInit} from '@angular/core';
import {shipData} from "../../interfaces/vrs_interfaces";
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-courier-brt-tracking',
  templateUrl: './courier-brt-tracking.component.html',
  styleUrls: ['./courier-brt-tracking.component.scss']
})
export class CourierBrtTrackingComponent implements OnInit {

  @Input() oShip: shipData = this.vrshippingService.getNewshipData();
  public bLoadingData: boolean = false;
  public bErrorLoadingData: boolean = false;
  public oTrackingResponse: any = null;
  public aPackStatus: Array<any> = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.brtTrackingRequest();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

 brtTrackingRequest() {

    this.bLoadingData = true;
    this.bErrorLoadingData = false;

    this.vrshippingService.postBRTTrackingRequestDB(this.oShip, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.brt && get_response.brt.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare il tracking: ' + JSON.stringify(get_response.brt.error));
          this.bErrorLoadingData = true;
        } else {

          if(get_response.brt.data){
            this.oTrackingResponse = get_response.brt.data;

          }
        }

        this.bLoadingData = false;
      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
        this.bErrorLoadingData = true;
      }
    );
  }

}
