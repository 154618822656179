import {Component, Input, OnInit} from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {courierServiceData, listinoData} from "../../interfaces/vrs_interfaces";

@Component({
  selector: 'app-courier-service',
  templateUrl: './courier-service.component.html',
  styleUrls: ['./courier-service.component.scss']
})
export class CourierServiceComponent implements OnInit {

  public bLoadingData: boolean = false;
  public bLoadingServices: boolean = false;
  public aList: Array<listinoData> = [];

  public oService: courierServiceData = this.vrshippingService.getNewCourierServiceData();
  public aCourierService: Array<courierServiceData> = [];

  @Input() code_courier: string = "";

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.getListinoListo();
    this.getCourierService();
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  getListinoListo() {

    this.bLoadingData = true;

    this.vrshippingService.getListinoListDB(this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.lists && get_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i listini' + ': ' + get_response.lists.error);
        } else {
          this.aList = get_response.lists;
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingData = false;
      });

  }

  getListinoDescription(listino_code: string) {
    if(this.aList.length > 0) return this.aList.filter(x => x.codice == listino_code)[0].description;
    return ("");
  }

  getCourierService() {

    this.bLoadingServices = true;

    this.vrshippingService.getCourierServiceListDB(this.code_courier, this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.services && get_response.services.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile caricare i servizi attivi' + ': ' + get_response.services.error);
        } else {
          this.aCourierService = get_response.services;
        }

        this.bLoadingServices = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore server imprevisto' + ': ' + error);
        this.bLoadingServices = false;
      });

  }

  insertCourierService() {

    this.bLoadingData = true;

    this.oService.courier_code = this.code_courier;

    this.vrshippingService.postCourierServiceInsertDB(this.oService, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.services && insert_response.services.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il servizio: ' + JSON.stringify(insert_response.profiles.error));
        } else {
          this.getCourierService();
          this.oService = this.vrshippingService.getNewCourierServiceData();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + JSON.stringify(error));
        this.bLoadingData = false;
      }
    );

  }

  updateCourierService(service: courierServiceData) {

    this.bLoadingData = true;

    this.vrshippingService.postCourierServiceUpdateDB(service, this.vrshippingService.userData.token).subscribe(update_response => {

        if(update_response.services && update_response.services.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile salvare il servizio: ' + JSON.stringify(update_response.profiles.error));
        } else {

        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + JSON.stringify(error));
        this.bLoadingData = false;
      }
    );

  }

  deleteCourierService(service: courierServiceData) {

    this.bLoadingData = true;

    this.vrshippingService.postCourierServiceDeleteDB(service, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.services && insert_response.services.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile eliminare il servizio: ' + JSON.stringify(insert_response.profiles.error));
        } else {
          this.getCourierService();
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
      }
    );

  }

}
