<app-loader *ngIf="bLoadingData"></app-loader>

<div class="container p-4 bg-white">
  <div class="row">
    <div class="col-12">
      <h3>Gestione utenti piattaforma</h3>
    </div>
  </div>

  <div class="row my-3 align-items-center">
    <div class="col-12 col-md-10 mt-3">
      <div class="accordion" id="">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed rounded" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              <span class="fas fa-filter me-1"></span>Filtri
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">

              <div class="d-flex flex-wrap align-items-center">
                <div class="me-2 flex-fill">
                  <label>Reference</label>
                  <input type="text" class="form-control" [(ngModel)]="oFilter.reference" placeholder="Cerca reference">
                </div>
                <div class="me-2 flex-fill">
                  <label>Cognome / Nome</label>
                  <input type="text" class="form-control" [(ngModel)]="oFilter.nome" placeholder="Cerca Nome/Cognome">
                </div>
                <div class="me-2 flex-fill">
                  <label>Email</label>
                  <input type="text" class="form-control" [(ngModel)]="oFilter.email" placeholder="Cerca Email">
                </div>
                <div class="me-2 flex-fill">
                  <label>Stato</label>
                  <select class="form-control" [(ngModel)]="oFilter.stato">
                    <option value="">-- Vedi tutti --</option>
                    <option value="A">Abilitato</option>
                    <option value="B">Bloccato</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2">
      <button class="btn flex-grow-1 btn-success me-2" (click)="editUser(0)"><span class="fa fa-plus me-1"></span>Nuovo Utente</button>
    </div>
  </div>

  <div class="row mt-3 pb-5">
    <div class="col-12">

      <ngx-datatable
        class="bootstrap mobile-table"
        [rows]="filterList(aUsers)"
        [loadingIndicator]="bLoadingData" [columnMode]="'flex'"
        [headerHeight]="50" [footerHeight] = "50"
        rowHeight="auto" [reorderable]="true" [limit]="20">

        <ngx-datatable-column prop="reference" name="Reference" [flexGrow]="0.5"></ngx-datatable-column>

        <ngx-datatable-column prop="azienda" name="Azienda" [flexGrow]="0.5"></ngx-datatable-column>

        <ngx-datatable-column name="Nome" prop="nome" [flexGrow]="1">
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{row.cognome}} {{row.nome}}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column prop="email" name="E-mail" [flexGrow]="1"></ngx-datatable-column>

        <ngx-datatable-column [flexGrow]="0.5">
          <ng-template ngx-datatable-header-template>
            <span>#</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button class="btn btn-sm btn-primary" (click)="editUser(row.id)">MODIFICA</button>
            <span class="ms-2 text-danger" *ngIf="row.stato == 'B'">Bloccato</span>
          </ng-template>
        </ngx-datatable-column>

      </ngx-datatable>

    </div>
  </div>

</div>
