import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public appVersion = environment.version;
  public appLine = environment.building;
  public appRevision = environment.revision;

  constructor() { }

  ngOnInit(): void {
  }

}
