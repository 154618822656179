import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VrshippingService} from "../../services/vrshipping.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {courierData, listinoShipDuplicateData} from "../../interfaces/vrs_interfaces";

@Component({
  selector: 'app-list-duplicator',
  templateUrl: './list-duplicator.component.html',
  styleUrls: ['./list-duplicator.component.scss']
})
export class ListDuplicatorComponent implements OnInit {

  @Input() courier_code: string = "";
  @Output() saveAnswer = new EventEmitter<boolean>();

  public bLoadingData: boolean = false;
  public oListDuplicatorData: listinoShipDuplicateData = this.vrshippingService.getNewListShipDuplicatorData();
  public aCourierList: Array<courierData> = [];
  public aDuplicationData: any = [];

  constructor(public vrshippingService: VrshippingService, private modalService: NgbModal,
              public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.oListDuplicatorData.origin_courier = this.courier_code;
    this.getCourierList();
  }

  getCourierList () {

    this.bLoadingData = true;

    this.vrshippingService.getCourierListDB(this.vrshippingService.userData.token).subscribe(get_response => {

        if(get_response.couriers && get_response.couriers.error) {
          //Error
          this.openDialog('danger', "Errore nel caricamento dei corrieri: " + get_response.couriers.error);
        } else {
          this.aCourierList = get_response.couriers;
          this.aCourierList = this.aCourierList.filter(x => x.code !== this.courier_code);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.bLoadingData = false;
        this.openDialog('danger', "Errore imprevisto del server: " + error.message);
      });
  }

  openDialog(type:string, message: string) {
    //type : danger / warning / success / confirm
    const modalRef = this.modalService.open(AlertDialogComponent, { size: 'md' });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
  }

  duplicateListinoShip(){

    this.bLoadingData = true;

    this.vrshippingService.postListinoDuplicateDB(this.oListDuplicatorData, this.vrshippingService.userData.token).subscribe(insert_response => {

        if(insert_response.lists && insert_response.lists.error) {
          //Error
          this.openDialog('danger', 'Attenzione, impossibile duplicare il listino: ' + JSON.stringify(insert_response.lists.error));
          this.aDuplicationData = [];
        } else {
          this.aDuplicationData = insert_response.lists;
          this.saveAnswer.emit(true);
        }

        this.bLoadingData = false;

      },
      error => {
        // API call error
        console.log(error);
        this.openDialog('danger', 'Attenzione, errore imprevisto del server: ' + error);
        this.bLoadingData = false;
        this.aDuplicationData = [];
      }
    );

  }

}
